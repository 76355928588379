import React, {
    ChangeEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Languages } from 'Language/Constants';
import { i18n } from 'Language';
import { RegisteredLetterMessageValidation } from 'types/DataValidation';
import {
    RegisteredLetterRecipient,
    UploadedDocumentState,
    UploadingFile,
} from 'RegisteredLetter/types';

// Flux
import AuthStore from 'Auth/stores/AuthStore';
import CustomerStore from 'Auth/stores/CustomerStore';

// Redux
import { useAppDispatch } from 'Store';
import { ReduxState } from 'Store';
import { fetchFolders } from 'Common/redux/Folder/actions';
import {
    clearedError,
    clearedIsSending,
    documentRemoved,
    documentsChangedOrder,
    fetchedSigningFlows,
    languageUpdated,
    resettedState,
    sendRegisteredLetter,
    setPreviewDocumentId,
    uploadSelectedDocuments,
    cancelUpload,
} from '../redux/reducer';

// Components
import RegisteredLetterDetails from 'RegisteredLetter/components/RegisteredLetterDetails';
import RegisteredLetterRecipientList from 'RegisteredLetter/components/RegisteredLetterRecipientList';
import RegisteredLetterEmailTemplate from 'RegisteredLetter/components/RegisteredLetterEmailTemplate';
import RegisteredLetterSendingState from 'RegisteredLetter/components/RegisteredLetterSendingState';
import Button from 'Common/components/Button';

import 'RegisteredLetter/views/CreateRegisteredLetterPage.scss';
import { DocumentDropZone } from 'Common/components/DocumentDropZone/';
import CasefileDocumentPreviewModal from 'Casefiles/components/casefiles2/CasefileDocumentPreviewModal';
import { fetchCustomer } from '../../Auth/redux/customer/actions';
import UIButton from 'Common/components/Vega/Button';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import UIBanner from 'Common/components/Vega/Banner';

export type Props = {
    documents: UploadedDocumentState[];
    language: Languages;
    emailTemplatesValidation: RegisteredLetterMessageValidation;
    isSending: boolean;
    modal: JSX.Element;
    previewDocumentId: number | null;
    recipients: RegisteredLetterRecipient[];
    title: string;
    uploadingDocuments: UploadingFile[];
    // We have to access the global Router context, wich isn't typed
    router: any;
};

export function CreateRegisteredLetterPage(props: Props) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const {
        documents,
        language,
        emailTemplatesValidation,
        previewDocumentId,
        recipients,
        title,
        uploadingDocuments,
        router,
    } = props;

    const setUpOnMount = useCallback(
        async (customerId, customerDefaultLanguage) => {
            await Promise.all([
                dispatch(resettedState()),
                dispatch(fetchedSigningFlows()),
                dispatch(fetchFolders()),
                dispatch(fetchCustomer(customerId)),
                dispatch(languageUpdated(customerDefaultLanguage)),
            ])
                .catch(() => {
                    setIsError(true);
                })
                .finally(() => {
                    if (setIsMounted) {
                        setIsMounted(true);
                    }
                });
        },
        [dispatch]
    );

    useEffect(() => {
        const customerId = AuthStore.getAuthDetails().cid;
        const { language } = CustomerStore.getCustomer(customerId);

        setUpOnMount(customerId, language);
    }, []);

    const handleSendRegisteredLetter = async () => {
        new Promise((res) => {
            res(dispatch(sendRegisteredLetter()));
        }).catch((error) => console.error(error));
    };

    const retry = () => {
        dispatch(clearedError());
    };

    const back = () => {
        dispatch(clearedError());
        dispatch(clearedIsSending());
    };

    const renderValidationText = () => {
        const validationRules = {
            'You must add a title': title.length > 0,
            'You must add at least one recipient': recipients.length > 0,
            'You must add at least one document': documents.length > 0,
            'You need to fill out both email subject & message':
                emailTemplatesValidation.initial.valid !== false,
        };

        return Object.keys(validationRules).filter(
            (k) => !validationRules[k]
        )?.[0];
    };

    const onLanguageChange = (language: Languages) => {
        dispatch(languageUpdated(language));
    };

    const handleOnRemoveDocument = (index: number) => {
        dispatch(documentRemoved(index));
    };

    const handleOnCancelUpload = (index: number) => {
        dispatch(cancelUpload(index));
    };

    const handleOnDrop = (files: File[]) => {
        if (files.length === 0) return;

        dispatch(uploadSelectedDocuments(files, language));
    };

    const handleOnDocumentPreview = (index: number) => {
        const documentId = documents[index].id;

        dispatch(setPreviewDocumentId(documentId));
    };

    const handleOnPreviewClose = () => {
        dispatch(setPreviewDocumentId(null));
    };

    const handleOnChangeDocumentOrder = (index: number, newIndex: number) => {
        dispatch(documentsChangedOrder({ index, newIndex }));
    };

    const handleFileAddLinkClick = () => {
        fileInputRef?.current?.click();
    };

    const handleFileUploadLink = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        dispatch(uploadSelectedDocuments(Array.from(e.target.files), language));

        // Reset the file input so the same file can be uploaded again
        e.target.value = '';
    };

    const handleUploadClick = () => {
        document.getElementById('fileupload')?.click();
    };

    const handleCloseErrorBanner = () => {
        setIsError(false);
    };

    const combinedDocuments = [...uploadingDocuments, ...documents];

    // Render when in sending state
    if (props.isSending) {
        return (
            <RegisteredLetterSendingState
                onRetry={retry}
                onBack={back}
                router={router}
            />
        );
    }

    return (
        <div>
            <Helmet>
                <title>{i18n`Create new registered letter`}</title>
            </Helmet>

            {props.modal && (
                <div
                    className="casefile-details-modal"
                    data-testid={'casefile-modal'}>
                    {props.modal}
                </div>
            )}

            <div className="white-container">
                <h3 className="title">{i18n`Create new registered letter`}</h3>

                {isError && (
                    <UIBanner
                        variant="danger"
                        layout="horizontal"
                        closeButton
                        onCloseClick={handleCloseErrorBanner}>
                        <span slot="titleText">
                            {i18n`banner.fetchingDataError`}
                        </span>
                    </UIBanner>
                )}

                <div className="content">
                    <RegisteredLetterDetails
                        onLanguageChange={onLanguageChange}
                    />

                    <RegisteredLetterRecipientList />
                    <div className="document-upload-form">
                        {LaunchDarkly.variation(
                            Flags.ENABLE_NEW_UI_CASE_FILE_CREATION
                        ) ? (
                            <div className="mb-1 flex justify-end">
                                <label className="file-input">
                                    <input
                                        type="file"
                                        name="files[]"
                                        id="fileupload"
                                        className="file"
                                        accept="application/pdf"
                                        onChange={handleFileUploadLink}
                                        ref={fileInputRef}
                                        multiple={true}
                                    />
                                    <UIButton
                                        priority="primary"
                                        data-testid="add-document-button"
                                        type="neutral"
                                        onClick={handleUploadClick}>
                                        <ArrowUpTrayIcon className="h-6 shrink-0" />
                                        {i18n('Upload file')}
                                    </UIButton>
                                </label>
                            </div>
                        ) : (
                            <label className="file-input">
                                <input
                                    type="file"
                                    name="files[]"
                                    className="file"
                                    accept="application/pdf"
                                    onChange={handleFileUploadLink}
                                    ref={fileInputRef}
                                    multiple={true}
                                />
                                <Button
                                    theme="blue"
                                    variant="text"
                                    className="mb"
                                    renderAsSpan
                                    icon="far fa-plus-circle"
                                    renderIconLeft={true}>
                                    {i18n('Upload document')}
                                </Button>
                            </label>
                        )}

                        <DocumentDropZone
                            acceptedFileTypes="application/pdf"
                            availableDocumentTypes={[]}
                            documents={combinedDocuments}
                            onAddFileLinkClick={handleFileAddLinkClick}
                            onChangeDocumentOrder={handleOnChangeDocumentOrder}
                            onDrop={handleOnDrop}
                            onPreview={handleOnDocumentPreview}
                            onRemove={handleOnRemoveDocument}
                            onCancelUpload={handleOnCancelUpload}
                            isRegisteredLetter={true}
                        />
                    </div>

                    <RegisteredLetterEmailTemplate />

                    <div className="pull-right-flex mt">
                        <span
                            className="text-yellow mr align-center flex"
                            data-testid="validation-message">
                            {renderValidationText() && (
                                <>
                                    <i
                                        className={
                                            'far fa-exclamation-circle'
                                        }></i>
                                    &nbsp;
                                    {i18n(renderValidationText())}
                                </>
                            )}
                        </span>
                        <Button
                            theme="green"
                            disabled={
                                !isMounted ||
                                renderValidationText() !== undefined
                            }
                            onClick={handleSendRegisteredLetter}
                            icon="far fa-paper-plane"
                            renderIconLeft={true}>
                            {i18n`Send registered letter`}
                        </Button>
                    </div>
                </div>
            </div>
            {previewDocumentId && (
                <CasefileDocumentPreviewModal
                    id={previewDocumentId}
                    uploadedDocuments={documents}
                    onOpen={(documentId: number) =>
                        dispatch(setPreviewDocumentId(documentId))
                    }
                    onClose={handleOnPreviewClose}
                />
            )}
        </div>
    );
}

export default connect((state: ReduxState) => ({
    documents: state.registeredLetter.instance.documents,
    language: state.registeredLetter.instance.language,
    emailTemplatesValidation:
        state.registeredLetter.view.validation.emailTemplates,
    isSending: state.registeredLetter.view.isSending,
    previewDocumentId: state.registeredLetter.view.previewDocumentId,
    recipients: state.registeredLetter.instance.recipients,
    title: state.registeredLetter.instance.title,
    uploadingDocuments: state.registeredLetter.view.uploadingDocuments,
}))(CreateRegisteredLetterPage);
