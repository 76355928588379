import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { i18n } from 'Language';
import DummyDocumentRow from 'Casefiles/components/casefiles/DummyDocumentRow';
import DraggableDocumentListItem from 'Casefiles/components/casefiles2/DraggableDocumentListItem';
import analytics from 'Common/Analytics';
import {
    DocumentEntity,
    DocumentType,
    FileToUpload,
    UploadingDocument,
} from 'types/Document';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { UploadedDocument } from 'types/CaseFile';
import UIInlineMessage from '../Vega/InlineMessage';
import {
    UploadStatuses,
    UploadStatus,
    UploadPercentages,
} from 'Casefiles/components/casefiles2/types';
import { getUploadPDFErrorDescription } from 'Casefiles/components/casefiles2/utils';
import DocumentStore from 'Casefiles/stores/DocumentStore';
import Constants from 'Constants';
import { AcceptedFileTypes } from 'types/FileType';
import { getDocumentIdentifier } from 'Common/utils/caseFile';
import { useSelector } from 'react-redux';
import { RegisteredLetterState, UploadingFile } from 'RegisteredLetter/types';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';

type Props = {
    availableDocumentTypes: DocumentType[];
    documents: (
        | File
        | DocumentEntity
        | UploadingDocument
        | UploadedDocument
        | UploadingFile
    )[];
    onAddFileLinkClick: () => void;
    onChangeDocumentOrder: (index: number, newIndex: number) => void;
    onDrop: (files: FileToUpload[]) => void;
    onEdit?: (index: number) => void;
    onPreview: (index: number) => void;
    /** Must be provided if onEdit is not provided */
    onRemove?: (index: number) => void;
    onCancelUpload?: (index: number) => void;
    acceptedFileTypes: AcceptedFileTypes;
    isRegisteredLetter?: boolean;
};
type ErrorMessageConfig = {
    descriptionKey: string;
    titleKey?: string;
    linkText?: string;
    linkValue?: string;
};
type State = {
    uploadStatuses: UploadStatuses;
    uploadPercentages: UploadPercentages;
};

const DocumentDropZone = ({
    availableDocumentTypes,
    documents,
    onAddFileLinkClick,
    onChangeDocumentOrder,
    onDrop,
    onEdit,
    onPreview,
    onRemove,
    onCancelUpload,
    acceptedFileTypes,
    isRegisteredLetter = false,
}: Props) => {
    const reduxUploadStatuses = useSelector(
        (state: { registeredLetter: RegisteredLetterState }) =>
            state.registeredLetter.view.uploadStatuses
    );

    const [state, setState] = useState<State>({
        uploadStatuses: isRegisteredLetter
            ? reduxUploadStatuses
            : DocumentStore.getUploadStatuses(),
        uploadPercentages: DocumentStore.getUploadPercentages(),
    });

    useEffect(() => {
        if (!isRegisteredLetter) {
            const handleStoreChange = () => {
                setState({
                    uploadStatuses: DocumentStore.getUploadStatuses(),
                    uploadPercentages: DocumentStore.getUploadPercentages(),
                });
            };

            DocumentStore.addChangeListener(handleStoreChange);

            return () => {
                DocumentStore.removeChangeListener(handleStoreChange);
            };
        }
    }, []);

    useEffect(() => {
        if (isRegisteredLetter) {
            setState((prevState) => ({
                ...prevState,
                uploadStatuses: reduxUploadStatuses,
            }));
        }
    }, [reduxUploadStatuses]);

    const isMultipartUploadEnabled = LaunchDarkly.variation(
        Flags.ENABLE_NEW_UPLOAD_ENDPOINT
    );
    const getErrorConfig = (status: UploadStatus): ErrorMessageConfig => {
        if (
            typeof status === 'object' &&
            'status' in status &&
            status.status === 'error'
        ) {
            const errorDescription = getUploadPDFErrorDescription(status);

            const config: ErrorMessageConfig = {
                descriptionKey: errorDescription,
            };

            if (errorDescription === 'timeoutError') {
                config.titleKey = 'timeoutErrorTitle';
            } else if (errorDescription === 'generalError') {
                config.linkText = 'Contact Support';
                config.linkValue = Constants.sites.supportRequest;
            }

            return config;
        }

        return {
            descriptionKey: isMultipartUploadEnabled
                ? 'processingDelayed'
                : 'uploadDelayed',
        };
    };

    const renderUploadMessage = (status: UploadStatus) => {
        if (
            status === 'delayed' ||
            (typeof status === 'object' &&
                'status' in status &&
                status.status === 'error')
        ) {
            const {
                descriptionKey,
                titleKey,
                linkText,
                linkValue,
            } = getErrorConfig(status);

            return (
                <div className="mt-1">
                    <UIInlineMessage
                        variant={status === 'delayed' ? 'accent' : 'danger'}
                        description={i18n(`uploadDocument.${descriptionKey}`)}
                        title={
                            titleKey ? i18n(`uploadDocument.${titleKey}`) : ''
                        }
                        hideCloseButton={true}
                        linkText={linkText ? i18n(linkText) : ''}
                        linkValue={linkValue}
                    />
                </div>
            );
        }
    };

    return (
        <Dropzone
            accept={acceptedFileTypes}
            className="document-list-v2"
            disableClick={true}
            disablePreview={true}
            onDrop={onDrop}>
            <ul className="w-full">
                {documents?.length === 0 && (
                    <div className="document-list-v2-empty">
                        <div className="document-list-empty-graphic">
                            <i className="far fa-file-alt" />
                            <i className="far fa-mouse-pointer" />
                        </div>
                        <h2>{i18n`Drag and drop files to upload`}</h2>
                        <p>{i18n`- or -`}</p>
                        <button
                            className="underline-link bg-transparent border-none"
                            role="button"
                            aria-label="Add your files to the send-out"
                            onClick={onAddFileLinkClick}>
                            <i className="fas fa-desktop" />
                            &nbsp;
                            {i18n`Select files from your computer`}
                        </button>
                    </div>
                )}

                <DummyDocumentRow />

                {documents?.map((file, index) => {
                    const documentId = getDocumentIdentifier(file);

                    const isValidFileType = (
                        f: any
                    ): f is UploadingDocument | UploadedDocument =>
                        '_id' in f || 'id' in f;

                    const transformedFile = isValidFileType(file)
                        ? file
                        : {
                              _id: '',
                              caseFileId: 0,
                              documentTypeId: 0,
                              file:
                                  file instanceof File
                                      ? file
                                      : new File([], 'placeholder'),
                              filename:
                                  file instanceof File
                                      ? file.name
                                      : 'placeholder',
                              name:
                                  file instanceof File
                                      ? file.name.replace(/\.[^/.]+$/, '')
                                      : 'placeholder',
                              order: 0,
                              fileType:
                                  file instanceof File
                                      ? (file.type as AcceptedFileTypes)
                                      : 'application/pdf',
                              fileSize: file instanceof File ? file.size : 0,
                          };

                    return (
                        <div key={index}>
                            <DraggableDocumentListItem
                                availableDocumentTypes={availableDocumentTypes}
                                {...(onEdit && { edit: () => onEdit(index) })}
                                {...(onRemove && {
                                    remove: () => onRemove(index),
                                })}
                                {...(onPreview && {
                                    preview: () => onPreview(index),
                                })}
                                {...(onCancelUpload && {
                                    cancelUpload: () => onCancelUpload(index),
                                })}
                                file={transformedFile}
                                index={index}
                                onChangeDocumentOrder={(
                                    ind: number,
                                    newIndex: number
                                ) => {
                                    analytics.track(
                                        'Casefile document reorder'
                                    );
                                    onChangeDocumentOrder(ind, newIndex);
                                }}
                                percentageUploaded={
                                    documentId
                                        ? state.uploadPercentages[documentId]
                                        : undefined
                                }
                                onUploadStatus={
                                    documentId
                                        ? state.uploadStatuses[documentId]
                                        : undefined
                                }
                            />
                            {documentId &&
                                state.uploadStatuses[documentId] &&
                                renderUploadMessage(
                                    state.uploadStatuses[documentId]
                                )}
                        </div>
                    );
                })}
            </ul>
        </Dropzone>
    );
};

export default DragDropContext(HTML5Backend)(DocumentDropZone);
