import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmailTemplate, TemplateType } from 'types/EmailTemplates';
import { CaseFileType } from 'types/CaseFile';
import {
    RegisteredLetterInstance,
    RegisteredLetterRecipient,
    RegisteredLetterState,
    RegisteredLetterView,
    UploadedDocumentState,
    UploadingFile,
} from '../types';
import { i18n } from 'Language';
import { Languages } from 'Language/Constants';
import { SigningAPI } from 'Api';
import { AppThunk } from 'Store';
import { validateEmailTemplate } from 'Casefiles/utils/casefileValidation';
import {
    temporaryPDFDownload,
    temporaryPDFUpload,
    temporaryPDFUploadMultipart,
} from 'Casefiles/utils/file';
import {
    addDocuments,
    addRecipients,
    createCaseFile,
    fetchDocumentType,
    transformRegLetterDetailsToPayload,
} from 'RegisteredLetter/utils';
import analytics from 'Common/Analytics';
import { UploadStatus } from 'Casefiles/components/casefiles2/types';
import uniqid from 'uniqid';
import axios, { CancelTokenSource } from 'axios';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';

// Initial state
export const initialState: RegisteredLetterState = {
    instance: {
        description: '',
        documents: [],
        emailTemplates: {
            [TemplateType.INITIAL]: {
                id: null,
                title: null,
                subject: '',
                message: '',
                custom: false,
                default: false,
            },
        },
        folderId: null,
        language: Languages.EN,
        recipients: [] as RegisteredLetterRecipient[],
        sensitiveData: true,
        title: '',
    } as RegisteredLetterInstance,
    view: {
        uploadingDocuments: [],
        previewDocumentId: null,
        error: null,
        isMounted: false,
        isSending: false,
        isSent: false,
        signingFlows: {} as Record<Languages, CaseFileType>,
        validation: {
            emailTemplates: {
                [TemplateType.INITIAL]: { valid: false, errors: {} },
            },
        },
        uploadStatuses: {},
        timeoutIds: {},
        cancelTokens: {},
    } as RegisteredLetterView,
};

const registeredLetterSlice = createSlice({
    name: 'registeredLetter',
    initialState,
    reducers: {
        setUploadStatus(
            state,
            action: PayloadAction<{ tempId: string; status: UploadStatus }>
        ) {
            const { tempId, status } = action.payload;

            state.view.uploadStatuses[tempId] = status;
        },
        setTimeoutId: (
            state,
            action: PayloadAction<{ tempId: string; timeoutId: number }>
        ) => {
            const { tempId, timeoutId } = action.payload;

            if (state.view.timeoutIds[tempId]) {
                window.clearTimeout(state.view.timeoutIds[tempId]);
            }

            state.view.timeoutIds[tempId] = timeoutId;
        },
        clearTimeout: (state, action: PayloadAction<string>) => {
            const tempId = action.payload;

            if (state.view.timeoutIds[tempId]) {
                window.clearTimeout(state.view.timeoutIds[tempId]);
                delete state.view.timeoutIds[tempId];
            }
        },
        setCancelToken(
            state,
            action: PayloadAction<{
                tempId: string;
                cancelToken: CancelTokenSource;
            }>
        ) {
            const { tempId, cancelToken } = action.payload;

            state.view.cancelTokens[tempId] = cancelToken;
        },
        cancelUpload(state, action: PayloadAction<number>) {
            const index = action.payload;
            const documentToCancel = state.view.uploadingDocuments[index];

            if (documentToCancel.tempId) {
                state.view.cancelTokens[documentToCancel.tempId]?.cancel();
                delete state.view.cancelTokens[documentToCancel.tempId];

                // Remove from uploading documents
                state.view.uploadingDocuments.splice(index, 1);

                // Cleanup states
                registeredLetterSlice.caseReducers.cleanupDocumentStates(
                    state,
                    {
                        type: 'cleanupDocumentStates',
                        payload: documentToCancel.tempId,
                    }
                );
            }
        },
        cleanupDocumentStates: (state, action: PayloadAction<string>) => {
            const tempId = action.payload;

            // Clear timeout
            if (state.view.timeoutIds[tempId]) {
                window.clearTimeout(state.view.timeoutIds[tempId]);
                delete state.view.timeoutIds[tempId];
            }

            // Remove upload status
            delete state.view.uploadStatuses[tempId];
        },
        clearedError: (state) => {
            state.view.error = initialState.view.error;
        },
        clearedIsSending: (state) => {
            state.view.isSending = initialState.view.isSending;
        },
        documentRemoved: (state, action: PayloadAction<number>) => {
            const combinedDocuments = [
                ...state.view.uploadingDocuments,
                ...state.instance.documents,
            ];
            const removedDocument = combinedDocuments[action.payload];

            if ('id' in removedDocument) {
                const indexInUploaded = state.instance.documents.findIndex(
                    (doc) => doc.tempId === removedDocument.tempId
                );

                state.instance.documents.splice(indexInUploaded, 1);
            } else {
                const indexInUploading = state.view.uploadingDocuments.findIndex(
                    (doc) => doc.tempId === removedDocument.tempId
                );

                state.view.uploadingDocuments.splice(indexInUploading, 1);
            }

            if (removedDocument.tempId) {
                registeredLetterSlice.caseReducers.cleanupDocumentStates(
                    state,
                    {
                        type: 'cleanupDocumentStates',
                        payload: removedDocument.tempId,
                    }
                );
            }
        },
        documentsChangedOrder: (
            state,
            action: PayloadAction<{ index: number; newIndex: number }>
        ) => {
            state.instance.documents.splice(
                action.payload.newIndex,
                0,
                state.instance.documents.splice(action.payload.index, 1)[0]
            );
        },
        documentsUploaded: (
            state,
            action: PayloadAction<UploadedDocumentState[]>
        ) => {
            state.instance.documents = [
                ...state.instance.documents,
                ...action.payload,
            ];

            const completedTempIds = new Set(
                action.payload.map((doc) => doc.tempId)
            );

            state.view.uploadingDocuments = state.view.uploadingDocuments.filter(
                (uploadingDoc) => !completedTempIds.has(uploadingDoc.tempId)
            );
        },
        emailTemplateUpdated: (
            state,
            action: PayloadAction<{
                type: TemplateType;
                template: EmailTemplate;
            }>
        ) => {
            state.instance.emailTemplates[action.payload.type] =
                action.payload.template;
            state.view.validation.emailTemplates[
                action.payload.type
            ] = validateEmailTemplate(action.payload.template);
        },
        folderUpdated: (state, action: PayloadAction<number>) => {
            state.instance.folderId = action.payload;
        },
        languageUpdated: (state, action: PayloadAction<Languages>) => {
            state.instance.language = action.payload;
        },
        recipientAdded: (
            state,
            action: PayloadAction<RegisteredLetterRecipient>
        ) => {
            state.instance.recipients.push(action.payload);
        },
        recipientRemoved: (state, action: PayloadAction<number>) => {
            state.instance.recipients.splice(action.payload, 1);
        },
        recipientUpdated: (
            state,
            action: PayloadAction<{
                recipient: RegisteredLetterRecipient;
                index: number;
            }>
        ) => {
            state.instance.recipients[action.payload.index] =
                action.payload.recipient;
        },
        resettedState: () => initialState,
        sendFailed: (state, action: PayloadAction<unknown>) => {
            state.view.error = action.payload;
        },
        sendRequested: (state) => {
            state.view.isSending = true;
        },
        sendSucceeded: (state) => {
            state.view.isSent = true;
        },
        sensitiveDataToggled: (state) => {
            state.instance.sensitiveData = !state.instance.sensitiveData;
        },
        setDocumentsUploading: (
            state,
            action: PayloadAction<UploadingFile[]>
        ) => {
            state.view.uploadingDocuments = [
                ...state.view.uploadingDocuments,
                ...action.payload,
            ];
        },
        setIsSending: (state) => {
            state.view.isSending = true;
        },
        setPreviewDocumentId: (state, action: PayloadAction<number | null>) => {
            state.view.previewDocumentId = action.payload;
        },
        setSigningFlows: (
            state,
            action: PayloadAction<Record<Languages, CaseFileType>>
        ) => {
            state.view.signingFlows = action.payload;
        },
        titleUpdated: (state, action: PayloadAction<string>) => {
            state.instance.title = action.payload;
        },
    },
});

export const setUploadStatusWithDelay = createAsyncThunk(
    'upload/setStatusWithDelay',
    async (
        payload: { tempId: string; status: UploadStatus },
        { dispatch, getState }
    ) => {
        const { tempId, status } = payload;

        dispatch(clearTimeout(tempId));

        dispatch(
            setUploadStatus({
                tempId,
                status,
            })
        );

        if (status === 'uploading') {
            const timeoutId = window.setTimeout(() => {
                const state = getState() as {
                    registeredLetter: RegisteredLetterState;
                };

                if (
                    state.registeredLetter.view.uploadStatuses[tempId] ===
                    'uploading'
                ) {
                    dispatch(
                        setUploadStatus({
                            tempId,
                            status: 'delayed',
                        })
                    );
                }
            }, 6000);

            dispatch(
                setTimeoutId({
                    tempId,
                    timeoutId,
                })
            );
        }

        return payload;
    }
);

export const fetchedSigningFlows = (): AppThunk => async (dispatch) => {
    try {
        const signingFlows = await SigningAPI.get(
            '/casefile/registered-letters/types'
        );

        dispatch(setSigningFlows(signingFlows));
    } catch (error) {
        throw new Error(i18n('Encountered an error, try reloading the page'));
    }
};

export const uploadDocument = (
    file: File,
    locale: Languages
): AppThunk => async (dispatch) => {
    const fileWithTempId: UploadingFile = Object.assign(file, {
        tempId: uniqid(),
    });

    const isMultipartUploadEnabled = LaunchDarkly.variation(
        Flags.ENABLE_NEW_UPLOAD_ENDPOINT
    );

    dispatch(setDocumentsUploading([fileWithTempId]));

    const cancelToken = axios.CancelToken.source();

    dispatch(setCancelToken({ tempId: fileWithTempId.tempId, cancelToken }));

    dispatch(
        setUploadStatusWithDelay({
            tempId: fileWithTempId.tempId,
            status: 'uploading',
        })
    );

    try {
        const [uploadedDoc] = isMultipartUploadEnabled
            ? await temporaryPDFUploadMultipart(
                  {
                      file,
                      filename: file.name,
                  },
                  locale,
                  undefined,
                  cancelToken.token
              )
            : await temporaryPDFUpload(
                  {
                      file,
                      filename: file.name,
                  },
                  cancelToken.token
              );

        const fileContent = await temporaryPDFDownload(uploadedDoc.id);

        const uploadedDocument: UploadedDocumentState = {
            name: uploadedDoc.title.replace(/\.[^/.]+$/, ''),
            filename: uploadedDoc.title,
            id: uploadedDoc.id,
            fileType: uploadedDoc.type,
            format: uploadedDoc.extension,
            fileContent,
            fileSize: fileWithTempId.size,
            tempId: fileWithTempId.tempId,
        };

        dispatch(
            setUploadStatus({
                tempId: fileWithTempId.tempId,
                status: 'completed',
            })
        );

        dispatch(documentsUploaded([uploadedDocument]));
    } catch (error) {
        if (axios.isCancel(error)) {
            return null;
        } else {
            dispatch(
                setUploadStatus({
                    tempId: fileWithTempId.tempId,
                    status: {
                        status: 'error',
                        message: error.message,
                        code: error.code || error.status,
                    },
                })
            );
        }
    }
};

export const uploadSelectedDocuments = (
    files: File[],
    locale: Languages
): AppThunk => async (dispatch) => {
    for (const file of files) {
        dispatch(uploadDocument(file, locale));
    }
};

export const sendRegisteredLetter = (): AppThunk => async (
    dispatch,
    getState
) => {
    dispatch(setIsSending());

    const { instance } = getState().registeredLetter;
    const detailsPayload = transformRegLetterDetailsToPayload(instance);
    const { documents, emailTemplates, folderId, recipients } = instance;
    const { initial: emailTemplate } = emailTemplates;
    let casefileId: number | undefined;

    try {
        // Create casefile
        casefileId = await createCaseFile(detailsPayload);

        // Link to folder
        await SigningAPI.post(`/folders/${folderId}/casefiles/${casefileId}`);

        //Add recipients (signers without roles)
        await addRecipients(
            casefileId,
            emailTemplate,
            recipients,
            instance.sensitiveData
        );

        const documentTypeId = await fetchDocumentType(casefileId);

        //Add documents
        await addDocuments(casefileId, documentTypeId, documents);

        //Send signing request
        await SigningAPI.patch(`/casefiles/${casefileId}/send`);

        analytics.track('registered letter - sent');

        dispatch(sendSucceeded());
    } catch (error) {
        if (casefileId) {
            SigningAPI.delete(`/casefiless/${casefileId}`).catch(() => {});
        }

        dispatch(sendFailed(error));

        throw error;
    }
};

export const {
    setUploadStatus,
    setTimeoutId,
    clearTimeout,
    clearedError,
    clearedIsSending,
    documentRemoved,
    documentsChangedOrder,
    documentsUploaded,
    emailTemplateUpdated,
    folderUpdated,
    languageUpdated,
    recipientAdded,
    recipientRemoved,
    recipientUpdated,
    resettedState,
    sendFailed,
    sendRequested,
    sendSucceeded,
    sensitiveDataToggled,
    setDocumentsUploading,
    setIsSending,
    setPreviewDocumentId,
    setSigningFlows,
    titleUpdated,
    cancelUpload,
    setCancelToken,
} = registeredLetterSlice.actions;

export default registeredLetterSlice.reducer;
