import { Languages } from 'Language/Constants';
import { UserRights, UserRoles } from './User';

export enum Flags {
    /**
     * If on, newly created users will have to complete all steps of the account activation process before being able to log in
     */
    USERS_MUST_COMPLETE_SETUP = 'users-must-complete-setup',
    /**
     * Allows access to teams features. (Experimental)
     * Users are able to create and add people to teams but teams aren't usable for managing access to resources yet.
     */
    TEAM_ACCESS = 'team-access',
    /**
     * Enables access to Forms List (`/forms/list`). Which was shut down on 02/20/2020.
     * This is only enabled to temporarily restore access to this component who highly depend on it and don't have a workaround to use the app without this feature.
     */
    FORM_LIST_ENABLED = 'form-list-enabled',
    /**
     * Enables account switcher in the user dropdown menu.
     */
    USER_SWITCHER_ENABLED = 'user-switcher-enabled',
    /**
     * @string
     * Controls access levels to the "New UI"
     * - disabled | No access to new UI
     * - enabled | Full access to new UI
     * - demo | Log to new UI, can go back to old UI
     * - peek | Log to old UI, can go to new UI
     */
    UI_V2 = 'ui-v2',
    /**
     * Controls access to Registered Letter
     */
    REGISTERED_LETTER_ACCESS = 'registered-letter-access',
    /**
     * Controls access to KYC
     */
    KYC_ACCESS = 'kyc-access',
    /**
     * Enables access to KYC management lists (`/kyc/manage`) that allows users to see pending/filled KYCs.
     */
    KYC_LIST_ENABLED = 'kyc-list-enabled',
    /**
     * Display product news feed in sidebar navigation
     */
    PRODUCT_NEWS_ENABLED = 'product-news-enabled',
    /**
     * If enabled, the text option in Touch Signatures will be disabled only if the documents are signed for a
     * customer that has itsme enabled.
     */
    TEXT_SIGNATURES_DISABLED = 'text-signatures-disabled',
    /**
     * Adds additional processing to printed documents from Penneo Printer by removing extra parentheses from
     * the document title incoming from the document extraction API. @see: PN-611
     */
    URI_CLEAN_PARENS = 'uri-clean-parens',
    /**
     * Enables contact list management feature
     */
    CONTACT_LIST_ACCESS = 'contact-list-access',
    /**
     * If on, enables menu item for "Business rules" in the navigation sidebar (Automate > Business Rules)
     */
    BUSINESS_RULES_ENABLED = 'business-rules-enabled',
    /**
     * Enables experimental client selector feature.
     * If on, an input with autocomplete will be rendered in the case file wizard,
     * in the first view where the case file details are set up. The input field will also fetch customer's clients
     * and after the case file is successfully created map its id with the selected client
     * and persist that data together with customer's id in its own db.
     */
    EXPERIMENT_CLIENT_SELECTOR_ENABLED = 'experiment-client-selector-enabled',
    /**
     * For a first version of the client selector we dont allow api usage by users with no danish vatin
     */
    NON_DANISH_VATIN = 'non-danish-vatin',
    /**
     * Allows the Finnish language to be visible and selectable in the UI.
     */
    FINNISH_LANGUAGE_AVAILABLE = 'finnish-language-available',
    /**
     * Allows the tour for the Dashboard to be visible.
     */
    DASHBOARD_TOUR_ENABLED = 'dashboard-tour-enabled',
    /**
     * Allows the tour for filling out a Form to be visible.
     */
    FILL_FORM_TOUR_ENABLED = 'fill-form-tour-enabled',
    /**
     * Allows conditional signing to be enabled
     */
    CONDITIONAL_SIGNING_ENABLED = 'conditional-signing-enabled',
    /**
     * Access to the external data dashboard
     */
    COMPANY_DATA_DASHBOARD_ENABLED = 'company-data-dashboard-enabled',
    /**
     *  Access to Entities (Client Selector's counterpart)
     *
     * */
    CLIENT_ENTITIES_ACCESS = 'client-entities-access',
    /**
     * Controls visibility of the signing flow overview
     */
    NEW_FLOW_OVERVIEW = 'new-signing-flow-overview',

    QRSS_SIGNATURE = 'qrss-signature',
    /**
     * Access to role specific activation date
     */
    ROLE_SPECIFIC_ACTIVATION_DATE_ENABLED = 'role-specific-activation-date-enabled',
    /**
     * Lets the user define different emails per signer
     */
    SIGNER_SPECIFIC_EMAILS = 'signer-specific-emails',

    /**
     * Toggles between using the EID webcomponent. IDT-39
     */
    USE_EID_WEBCOMPONENT = 'eid-webcomponent',

    /**
     * Will redirect signers in V2 to a variation of a post-signing page after they finish their signing process
     */
    SIGNING_PAGE_A_B_TEST_REDIRECT = 'signing-page-a-b-test-redirect',

    /**
     * Permanent flag for experimental EIDs.
     *
     * The value of this flag is an array, and should be used together with EID/types.EIDMethod.availability.
     *
     * The `EIDMethod.type` value must be present in the array for it to be available,
     * In local dev and staging, '*' can be used as a wildcard.
     *
     * You can also only enable a certain intent (sign, login) by adding `<EIDMethod.type>:<intent>` instead of just the
     * type.
     *
     * Link: https://app.launchdarkly.com/development/production/features/experimental-eids/targeting
     *
     * Note: the downside of doing things this way is that we can't filter for specific customers, most EID use happens
     * when users are not logged in, so we couldn't do that anyway.
     */
    EXPERIMENTAL_EIDS_ENABLED = 'experimental-eids',
    /**
     * Show 'Touch' (insecure signing methods) separately
     */
    SPLIT_INSECURE_SIGNING_METHODS = 'split-insecure-signing-methods',

    /**
     * Allow admins to enable SMS verification for the organisation
     */
    SMS_VERIFICATION = 'sms-verification',

    /**
     * Enable itsme QES for signing
     */
    USE_ITSME_QES = 'use-itsme-qes',

    /**
     * Enable the new Clients feature
     */
    CLIENTS = 'clients',

    /**
     * Enables hidding detailed casefile data to user outside the sender's company
     */
    SIGNER_VIEW = 'signer-view',

    /**
     * [IDT-191] When this feature is enabled, users with a NemID archive but no a MitID archive
     * will get suggested to link their MitID with their archive when they attempt to log in using MitID.
     */
    RECLAIM_NEMID_ACCOUNT = 'reclaim-nemid-account',
    /**
     * Enables German language support in the application
     */
    GERMAN_LANGUAGE_AVAILABLE = 'german-language-availability',
    /**
     * [IDT-256] When enabled the user will be redirected to "name-address-protection/:challengeKey"
     *  if they have Name and Address protection in MitID
     */
    ENABLE_NAP_INTERVENTION_SCREEN = 'enable-nap-intervention-screen',
    /**
     * Enables intercom globally for the application.
     */
    INTERCOM_ENABLED = 'intercom-enabled',
    /**
     * Enables updated view for adding/editing recipients on casefile
     */
    IMPROVED_RECIPIENT_SETTINGS = 'improved-recipient-settings',
    /**
     * Enables viewing of completed casefiles via a signing link, if the API indicates that it is possible.
     */
    VIEW_COMPLETED_CASEFILES_VIA_SIGNING_LINK = 'view-completed-casefiles-via-signing-link',
    /**
     * Enables BankID Norway through E-Ident as a login, signing and ID validation method
     */
    BANKID_NORWAY_EIDENT_ENABLED = 'bankid-norway-eident-enabled',

    /**
     * Enables BankID Norway Biometric through E-IDent as a login, signing, and ID validation method
     */
    BANKID_NORWAY_EIDENT_BIOMETRIC_ENABLED = 'bankid-norway-eident-biometric-enabled',

    /**
     * Enables displaying activation date editor for casefiles with multiple signing rounds
     */
    ACTIVATION_DATE_PER_ROUND = 'activation-date-per-round',

    /**
     * Enable large file uploads through a beta endpoint
     */
    LARGE_FILE_UPLOAD = 'large-file-upload',

    /**
     * Enable OIDC ID token recording
     */
    OIDC_ID_TOKEN_RECORDING = 'oidc-id-token-recording',

    /**
     * [SUP-158] Zendesk SSO - Integration with SIGN
     */
    ENABLE_ZENDESK_SSO_LOGIN_HELP_CENTER = 'enable-zendesk_sso_login_help_center',

    /**
     * Enables formatting service for documents
     */
    ENABLE_FORMATTING_SERVICE = 'enable-formatting-service',

    /**
     * Enables new upload endpoint for documents
     */
    ENABLE_NEW_UPLOAD_ENDPOINT = 'enable-media-upload-endpoint',

    /**
     * Enables XBRL format for documents
     */
    ENABLE_XBRL = 'enableIXBRLUpload',

    /**
     * Enables the new signers archive
     */
    ENABLE_SIGNERS_ARCHIVE = 'enable-signers-archive',

    /**
     * Enables the new signing flow
     */
    ENABLE_NEW_UI_CASE_FILE_CREATION = 'enable-new-ui-case-file-creation',
}

export type LaunchDarklyUserObject = {
    key: string;
    custom: {
        language: Languages;
        admin: boolean;
        role: UserRoles;
        customerId: number | false;
        rights: UserRights[];
        'customer.status': number | false;
    };
};
