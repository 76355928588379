import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import UserManagementActions from '../../actions/UserManagementActionCreators';
import UserManagementStore from '../../stores/UserManagementStore';
import assign from 'object-assign';
import { i18n, TranslationStore } from 'Language';
import UserStore from '../../stores/UserStore';
import { AUTH_METHODS, AuthMethod } from 'Constants';
import {
    LOGIN_METHODS,
    LoginMethod,
    isMethodAvailableToConfigureForLogin,
} from 'EID';
import Button from 'Common/components/Button';
import { UserEntity as User, UserRights, UserRoles } from 'types/User';
import { notify } from 'react-notify-toast';
import { uniqBy } from 'lodash';
import { CustomerSigningMethod } from '../../../types/Customer';
import { ReduxState } from 'Store';
import { ManagedSettings } from 'Settings/redux/types';

type Contact = {
    fullName: string;
    email: string;
};

type Props = {
    params: {
        customerId: string;
    };
    managedSettings: ManagedSettings;
};

type State = {
    contact: Contact;
    rights: UserRights[];
    role: UserRoles;
    credentials: string[];
    dispatchedAction: string | null;
    user: User;
    inviteContact: Contact | null;
    error: {
        message: string;
    } | null;
};

export class UserCreation extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    state: State = {
        contact: {
            fullName: '',
            email: '',
        },
        rights: [UserRights.SEND, UserRights.__DEPRECATED__VALIDATION],
        role: UserRoles.USER,
        credentials: ['classic'],
        dispatchedAction: null,
        user: UserStore.getCurrentUser(),
        inviteContact: null,
        error: null,
    };

    componentDidMount() {
        UserStore.addChangeListener(this.onChange);
        TranslationStore.addChangeListener(this.onChange);
        UserManagementStore.addEventListener(
            'ACTION_DISPATCHED',
            this.onChange
        );
    }

    componentWillUnmount() {
        UserStore.removeChangeListener(this.onChange);
        TranslationStore.removeChangeListener(this.onChange);
        UserManagementStore.removeEventListener(
            'ACTION_DISPATCHED',
            this.onChange
        );
    }

    resetComponentState = () => {
        this.setState({
            contact: {
                fullName: '',
                email: '',
            },
            rights: [],
            role: UserRoles.USER,
            credentials: [],
            user: UserStore.getCurrentUser(),
            dispatchedAction: null,
        });
    };

    onChange = (action: string) => {
        if (action) {
            this.setState({
                dispatchedAction: action,
            });
        }

        if (action === 'USER_CREATION_SUCCESS') {
            // Duplicate Contact before clearing for notification purposes.
            const inviteContact = assign({}, this.state.contact);

            this.setState(
                {
                    inviteContact: inviteContact,
                    contact: {
                        fullName: '',
                        email: '',
                    },
                    rights: [
                        UserRights.SEND,
                        UserRights.__DEPRECATED__VALIDATION,
                    ],
                    role: UserRoles.USER,
                    credentials: ['classic'],
                },
                () => {
                    //notify
                    this.renderSuccess();
                }
            );
        }

        if (action === 'USER_CREATION_FAILURE') {
            this.renderFailure();
        }

        this.setState({
            user: UserStore.getCurrentUser(),
        });
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const {
            contact,
            rights,
            credentials,
            role,
            dispatchedAction,
        } = this.state;
        const processingRequest = dispatchedAction === 'USER_CREATION_REQUEST';

        if (processingRequest) {
            return;
        }

        if (rights.length === 0 || credentials.length === 0) {
            return this.setState(
                {
                    error: {
                        message: `You cannot create a user without specifying rights and credentials`,
                    },
                },
                () => this.renderErrorMessage(this.state.error)
            );
        }

        const { customerId } = this.props.params;
        const userDetails = assign(contact, { rights: rights, enabled: true });

        UserManagementActions.createUser(
            userDetails,
            credentials,
            customerId,
            role
        );
    };

    handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ role: event.target.value as UserRoles });
    };

    handleRightsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { rights } = this.state;

        if (event.target.checked) {
            rights.push(event.target.name as UserRights);
        } else {
            const index = rights.indexOf(event.target.name as UserRights);

            rights.splice(index, 1);
        }

        this.setState({ rights: rights });
    };

    handleCredentialsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { credentials } = this.state;

        if (event.target.checked) {
            credentials.push(event.target.name);
        } else {
            const index = credentials.indexOf(event.target.name);

            credentials.splice(index, 1);
        }

        this.setState({ credentials: credentials });
    };

    handleContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { contact } = this.state;

        contact[event.target.name] = event.target.value;

        this.setState({ contact: contact });
    };

    renderContent = (action: string | null) => {
        const { contact, rights, credentials, user } = this.state;
        const processingRequest = action === 'USER_CREATION_REQUEST';

        if (!user) {
            return false;
        }

        const filteredEIDMethods = LOGIN_METHODS.filter((method) =>
            isMethodAvailableToConfigureForLogin(
                method,
                this.props.managedSettings
            )
        );

        const methods = uniqBy(
            (AUTH_METHODS as (AuthMethod | LoginMethod)[]).concat(
                filteredEIDMethods
            ),
            (method) => method.credentialType()
        )
            // TODO: Filter out SMS with a proper filter https://linear.app/penneo/issue/IDT-456
            .filter((method) => method.type !== CustomerSigningMethod.SMS);

        return (
            <form className="form" onSubmit={this.handleSubmit}>
                <div>
                    <label htmlFor="name">{i18n('Name')}</label>
                    <input
                        type="text"
                        name="fullName"
                        value={contact.fullName || ''}
                        onChange={this.handleContactChange}
                        required
                    />

                    <label htmlFor="email">{i18n('Email address')}</label>
                    <input
                        type="email"
                        name="email"
                        value={contact.email || ''}
                        onChange={this.handleContactChange}
                        required
                    />

                    <label htmlFor="role">{i18n('Role')}</label>
                    <select name="role" onChange={this.handleRoleChange}>
                        <option value={UserRoles.USER}>{i18n('User')}</option>
                        <option value={UserRoles.CUSTOMER_ADMINISTRATOR}>
                            {i18n('Administrator')}
                        </option>
                    </select>

                    <label>{i18n('Rights')}</label>
                    <div className="checkbox-group">
                        <label className="custom-checkbox">
                            <input
                                type="checkbox"
                                name={UserRights.SEND}
                                checked={rights.indexOf(UserRights.SEND) !== -1}
                                onChange={this.handleRightsChange}
                            />
                            <span className="check">
                                <i className="fas fa-check" />
                            </span>
                            <span>{i18n('Send documents')}</span>
                        </label>

                        <label className="custom-checkbox">
                            <input
                                type="checkbox"
                                name={UserRights.KYC}
                                checked={rights.indexOf(UserRights.KYC) !== -1}
                                onChange={this.handleRightsChange}
                            />
                            <span className="check">
                                <i className="fas fa-check" />
                            </span>
                            <span>{i18n('Identity validation')}</span>
                        </label>

                        {user.admin && (
                            <label className="custom-checkbox">
                                <input
                                    type="checkbox"
                                    name={UserRights.FORMS}
                                    checked={
                                        rights.indexOf(UserRights.FORMS) !== -1
                                    }
                                    onChange={this.handleRightsChange}
                                />
                                <span className="check">
                                    <i className="fas fa-check" />
                                </span>
                                <span>{i18n('Forms')}</span>
                            </label>
                        )}
                    </div>

                    <label className="mt">{i18n('Login methods')}</label>
                    <div className="checkbox-group">
                        {methods.map((method) => (
                            <label
                                className="custom-checkbox"
                                key={method.credentialType()}>
                                <input
                                    type="checkbox"
                                    name={method.credentialType()}
                                    checked={
                                        credentials.indexOf(
                                            method.credentialType()
                                        ) !== -1
                                    }
                                    onChange={this.handleCredentialsChange}
                                />
                                <span className="check">
                                    <i className="fas fa-check" />
                                </span>
                                <span>{i18n(method.title)}</span>
                            </label>
                        ))}
                    </div>
                </div>
                <br />
                <div className="pull-right-flex">
                    <Button
                        theme="blue"
                        disabled={processingRequest}
                        icon={
                            !processingRequest
                                ? 'far fa-plus'
                                : 'far fa-sync fa-spin'
                        }>
                        {!processingRequest ? i18n`Create user` : i18n`Saving`}
                    </Button>
                </div>
            </form>
        );
    };

    renderSuccess = () => {
        const { inviteContact } = this.state;

        if (!inviteContact) {
            return;
        }

        notify.show(
            <div>
                <h3 style={{ marginTop: '0px' }}>
                    {i18n('User created successfully')}
                </h3>
                <p>
                    {i18n(
                        'An email was sent to ' +
                            inviteContact.fullName +
                            ' at ' +
                            inviteContact.email
                    )}
                </p>
            </div>,
            'success',
            5000
        );
    };

    renderFailure = () =>
        notify.show(
            i18n(`
        There was an error creating the user. Please, Try again.
    `),
            'error',
            5000
        );

    renderErrorMessage = (error) => {
        notify.show(i18n(error.message), 'error', 5000);
        this.setState({
            error: null,
        });
    };

    render() {
        return (
            <div className="penneo-auth">
                <div className="user-creation white-container">
                    <h3 className="title">{i18n('Create user')}</h3>
                    <div className="content">
                        {this.renderContent(this.state.dispatchedAction)}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    managedSettings: state.settings.data.managed,
}))(UserCreation);
