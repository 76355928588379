import PropTypes from 'prop-types';
import React from 'react';
import AuthStore from 'Auth/stores/AuthStore';
import CustomerStore from 'Auth/stores/CustomerStore';
import UserManagementStore from 'Auth/stores/UserManagementStore';
import UserManagementActions from 'Auth/actions/UserManagementActionCreators';

import { modal } from 'Common/components/Common/Modal';
import Loading from 'Common/components/Loaders/LoadingData';
import { Link } from 'react-router';
import { i18n } from 'Language';
import Griddle from 'griddle-react';
import ClickDropdown from 'Common/components/Common/ClickDropdown';

import DeleteUserPrompt from './DeleteUserPrompt';
import Button from 'Common/components/Button';
import { UserEntity as User, UserRights } from 'types/User';
import { CustomerEntity as Customer } from 'types/Customer';
import { CreateLink } from 'types/Router';
import { Router } from 'react-router';

type TableRowData = {
    Actions: string;
    Email: string;
    ID: number;
    Name: string;
    Rights: string[];
    Status: boolean;
};

type Props = {
    route: {
        name: string;
        path: string;
        createLink: CreateLink;
    };
    customer: Customer;
};

type State = {
    user: User;
    currentCustomerId: number | string;
    users: User[];
};

type AppContextType = {
    router: Router;
};

export default class UserList extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };
    context!: AppContextType;

    state: State = {
        user: AuthStore.getUser(),
        currentCustomerId: AuthStore.getUser().customerIds[0],
        users: [],
    };

    componentDidMount() {
        UserManagementStore.addChangeListener(this.onChange);
        this.loadData();
    }

    componentWillUnmount() {
        UserManagementStore.removeChangeListener(this.onChange);
    }

    loadData = () => {
        const customerId = this.props.customer.id;

        UserManagementActions.fetchUsers(customerId);
    };

    onChange = () => {
        this.setState({
            users: UserManagementStore.getUsers(),
        });
    };

    // @todo: This is only evaluated on first render.
    // On language change, column names are not updated.
    getColumnMetaData = () => {
        return [
            {
                columnName: 'ID',
                cssClassName: 'column-minimize sortable',
            },
            {
                columnName: 'Name',
                cssClassName: 'sortable',
                displayName: i18n`Name`,
            },
            {
                columnName: 'Email',
                cssClassName: 'sortable',
                displayName: i18n`Email`,
            },
            {
                columnName: 'Status',
                cssClassName: 'column-minimize sortable',
                displayName: i18n`Status`,
                customComponent: StatusColumn,
            },
            {
                columnName: 'Rights',
                cssClassName: 'column-minimize no-border-right sortable',
                displayName: i18n`Rights`,
                customComponent: RightsColumn,
            },
            {
                columnName: 'Actions',
                displayName: <span>&nbsp;</span>, // Render blank header.
                customComponent: DetailsColumn(this.props.route.createLink),
                cssClassName:
                    'column-minimize column-no-padding unsortable column-show-on-row-hover',
            },
        ];
    };

    handleRowClick = (rowData: TableRowData) => {
        const { router } = this.context;
        const { customer, route } = this.props;
        const isCurrentUser = rowData.ID === this.state.user.id;

        if (isCurrentUser) {
            return { name: 'profile' };
        }

        router.push(
            route.createLink('manage-user', {
                userId: rowData.ID,
                customerId: customer.id,
            })
        );
    };

    // Convert rights to user understandable names.
    parseRights = (rights: UserRights[]) => {
        if (!rights) {
            return [];
        }

        return rights
            .map((r) => {
                if (r === UserRights.SEND) {
                    return 'Send documents';
                }

                if (r === UserRights.FORMS) {
                    return 'Forms';
                }

                if (r === UserRights.KYC) {
                    return 'Identity validation';
                }

                return r;
            })
            .filter((r) => r !== UserRights.__DEPRECATED__VALIDATION);
    };

    renderTable = () => {
        const { users, currentCustomerId } = this.state;
        const { route, customer } = this.props;

        const selectedCustomer =
            customer || CustomerStore.getCustomer(currentCustomerId);

        const data: TableRowData[] = users.map((user) => {
            return {
                ID: user.id,
                Name: user.fullName,
                Email: user.email,
                Status: user.active,
                Rights: this.parseRights(user.rights),
                Actions: JSON.stringify({
                    customerId: customer.id,
                    transferOwnershipOnUserDelete:
                        customer.transferOwnershipOnUserDelete,
                    userId: user.id,
                    isCurrent: user.id === this.state.user.id,
                    currentUserId: this.state.user.id,
                }),
            };
        });

        if (data.length === 0) {
            return <Loading />;
        }

        return (
            <Griddle
                tableClassName="griddle-table"
                onRowClick={({ props }) => this.handleRowClick(props.data)}
                results={data}
                resultsPerPage={10}
                showFilter={true}
                noDataMessage={''}
                useCustomFilterComponent={true}
                initialSort={'Name'}
                initialSortAscending={false}
                customFilterComponent={CustomHeader(
                    route.createLink,
                    selectedCustomer
                )}
                useGriddleStyles={false}
                nextText={i18n`Next`}
                previousText={i18n`Previous`}
                columnMetadata={this.getColumnMetaData()}
            />
        );
    };

    render() {
        return (
            <div className="penneo-auth">
                <div className="user-list">{this.renderTable()}</div>
            </div>
        );
    }
}

class StatusColumn extends React.Component<{ data: boolean }> {
    render() {
        const active = this.props.data;

        if (active) {
            return (
                <div className={`casefile-status green`}>
                    <span className="casefile-status-name no-wrap">
                        {i18n`Active`}&nbsp;
                        <i className={`far fa-user`} />
                    </span>
                </div>
            );
        }

        return (
            <div className={`casefile-status yellow`}>
                <span className="casefile-status-name no-wrap">
                    {i18n`Inactive`}&nbsp;
                    <i className={`far fa-user-slash`} />
                </span>
            </div>
        );
    }
}

class RightsColumn extends React.Component<{ data: string[] }> {
    render() {
        const rights = this.props.data;

        return (
            <div className="no-wrap">
                {rights.map((r) => (
                    <span className="tag" key={r}>
                        {i18n(r)}
                    </span>
                ))}
            </div>
        );
    }
}

type ParsedDetailsColumnProps = {
    currentUserId: number;
    customerId: number;
    isCurrent: boolean;
    transferOwnershipOnUserDelete: boolean;
    userId: number;
};

const DetailsColumn = (createLink: CreateLink) =>
    class extends React.Component<{ data: string }> {
        getLink = () => {
            const {
                userId,
                customerId,
                isCurrent,
            }: ParsedDetailsColumnProps = JSON.parse(this.props.data);

            if (isCurrent) {
                return { name: 'profile' };
            }

            return createLink('manage-user', {
                userId: userId,
                customerId: customerId,
            });
        };

        promptDelete = () => {
            const {
                userId,
                customerId,
                transferOwnershipOnUserDelete,
                currentUserId,
            }: ParsedDetailsColumnProps = JSON.parse(this.props.data);

            const config = {
                title: i18n('Delete user'),
                body: (
                    <DeleteUserPrompt
                        userIds={[userId]}
                        customerId={customerId}
                        currentUserId={currentUserId}
                        allowTransfer={transferOwnershipOnUserDelete}
                        onAccept={modal.hide}
                        onCancel={modal.hide}
                    />
                ),
                focusTrapWaitForElement: '.delete-user-prompt .buttons',
            };

            modal.show(config);
        };

        render() {
            const { isCurrent }: ParsedDetailsColumnProps = JSON.parse(
                this.props.data
            );

            return (
                <div onClick={(e) => e.stopPropagation()}>
                    <ClickDropdown
                        placement="bottom left"
                        offset={-60}
                        content={
                            <div className="click-dropdown-list">
                                <ul>
                                    <Link to={this.getLink()}>
                                        <li>
                                            &nbsp;
                                            <i className="far fa-pencil" />
                                            &nbsp;
                                            {i18n('Edit user')}
                                        </li>
                                    </Link>
                                    {!isCurrent && (
                                        <li onClick={this.promptDelete}>
                                            &nbsp;
                                            <i className="far text-red fa-times" />
                                            &nbsp;&nbsp;&nbsp;
                                            {i18n('Delete user')}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        }>
                        <Button
                            style={{
                                paddingTop: '2px',
                                paddingBottom: '2px',
                                paddingRight: '8px',
                                paddingLeft: '8px',
                            }}
                            variant="solid"
                            theme="blue">
                            <i className="fas fa-ellipsis-h"></i>
                        </Button>
                    </ClickDropdown>
                </div>
            );
        }
    };

type CustomerHeaderProps = {
    changeFilter: Function;
    currentResults: TableRowData[];
};

const CustomHeader = (createLink: CreateLink, customer: Customer) =>
    class extends React.Component<CustomerHeaderProps> {
        searchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            this.props.changeFilter(event.target.value);
        };

        render() {
            const { currentResults } = this.props;

            return (
                <div className="container-header" style={{ paddingBottom: 0 }}>
                    <span className="search-bar">
                        <input
                            type="text"
                            style={{ width: 200 }}
                            onChange={this.searchChange}
                            placeholder={i18n('Type to filter')}
                        />
                    </span>

                    <span className="text-bold pl">
                        {`${currentResults.length} ` + i18n('Users')}
                    </span>

                    <div className="pull-right">
                        <Link
                            to={createLink('create-user', {
                                customerId: customer.id,
                            })}>
                            <Button
                                theme="blue"
                                variant="outline"
                                icon="far fa-user-plus">
                                {i18n('Create new')}
                            </Button>
                        </Link>
                    </div>
                </div>
            );
        }
    };
