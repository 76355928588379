import React from 'react';

import CustomerPreferenceActions from 'Auth/actions/CustomerPreferenceActions';
import { i18n, translationsPreservedSpaces } from 'Language';
import { modal } from 'Common/components/Common/Modal';

import { KYCDefaultDescription, KYCPreferencesKey } from '../Constants';
// Redux
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import { updateDescription } from 'KYC/redux/actions';

import './kyc-advanced-options.scss';
import Button from 'Common/components/Button';
import { ToastContext, ToastContextType } from 'Common/components/ToastContext';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

export type Props = {
    dispatch: AppDispatch;
    description: string;
    language: string;
};

type State = {
    advanced: boolean;
    initialDescription: string;
    isUsingDefaultDescription: boolean;
    isChangeSaved: boolean;
};

export class KYCAdvancedOptions extends React.Component<Props, State> {
    declare context: ToastContextType;
    static contextType = ToastContext;

    private get addToast() {
        const toast = this.context;

        if (!toast) {
            console.error('ToastContext not found');

            return;
        }

        return toast?.addToast;
    }

    translations = null;

    constructor(props: Props) {
        super(props);
        this.translations = translationsPreservedSpaces as any;
        this.state = {
            advanced: false,
            initialDescription: '',
            isUsingDefaultDescription: false,
            isChangeSaved: false,
        };
    }

    componentDidMount = async () => {
        const { dispatch, language } = this.props;
        const kycCustomerPreferences = await CustomerPreferenceActions.fetchPreferences(
            KYCPreferencesKey
        );

        let initialDescription, isUsingDefaultDescription;

        await (this.translations as any)?.changeLanguage(language);

        if (kycCustomerPreferences && kycCustomerPreferences.description) {
            initialDescription = kycCustomerPreferences.description;
            isUsingDefaultDescription = false;
        } else {
            const i18n = this.getTranslationFn();

            initialDescription = i18n(KYCDefaultDescription);
            isUsingDefaultDescription = true;
        }

        this.setState({ initialDescription, isUsingDefaultDescription });
        dispatch(updateDescription(initialDescription));
    };

    async componentDidUpdate(prevProps) {
        const { dispatch, language } = this.props;
        const { language: prevLanguage } = prevProps;
        const { isUsingDefaultDescription } = this.state;

        const hasLanguageChanged = language !== prevLanguage;

        if (!hasLanguageChanged || !isUsingDefaultDescription) {
            return;
        }

        if (hasLanguageChanged) {
            await (this.translations as any).changeLanguage(language);
        }

        const i18n = this.getTranslationFn();
        const translatedDescription = i18n(KYCDefaultDescription);

        dispatch(updateDescription(translatedDescription));
        this.setState({ initialDescription: translatedDescription });
    }

    /**
     * Builds a custom translation function using the language passed as prop,
     * rather than the current language of the app
     */
    getTranslationFn(): Function {
        return (this.translations as any).i18n as Function;
    }

    handleDescriptionChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { dispatch } = this.props;
        const { value } = event.target;

        this.setState({ isUsingDefaultDescription: false });
        dispatch(updateDescription(value));
    };

    toggleAdvanced = () => {
        this.setState((prevState) => ({
            advanced: !prevState.advanced,
        }));
    };

    saveCustomKYCDescription = async () => {
        const { description } = this.props;

        try {
            await CustomerPreferenceActions.saveNewPreferences(
                {
                    value: {
                        description: description,
                    },
                },
                KYCPreferencesKey
            );

            this.addToast?.(
                undefined,
                i18n`Your custom description was saved`,
                'accent',
                10000,
                i18n`toast.cancelButton`,
                undefined,
                undefined,
                CheckCircleIcon
            );

            this.setState({
                initialDescription: description,
                isChangeSaved: true,
            });
        } catch {
            this.addToast?.(
                undefined,
                i18n`Could not save your custom description`,
                'danger',
                10000,
                i18n`toast.cancelButton`
            );
        }
    };

    showRestoreDefaultConfirm = () => {
        const config = {
            className: 'penneo-casefiles',
            body: i18n`Are you sure you want to restore default KYC description?`,
            buttons: (
                <div>
                    <Button theme="gray" variant="outline" onClick={modal.hide}>
                        {i18n`No, cancel`}
                    </Button>
                    <Button
                        theme="blue"
                        variant="solid"
                        onClick={() => {
                            this.restoreDefault();
                            modal.hide();
                        }}>
                        {i18n('Yes, restore')}
                    </Button>
                </div>
            ),
        };

        modal.show(config);
    };

    restoreDefault = () => {
        const { dispatch } = this.props;
        const i18n = this.getTranslationFn();
        const defaultDescription = i18n(KYCDefaultDescription);

        this.setState({
            initialDescription: defaultDescription,
            isUsingDefaultDescription: true,
        });
        dispatch(updateDescription(defaultDescription));
        CustomerPreferenceActions.saveNewPreferences(
            { value: {} },
            KYCPreferencesKey
        );
    };

    render() {
        const { description } = this.props;
        let { advanced, initialDescription, isChangeSaved } = this.state;
        let isInitial = description === initialDescription;

        return (
            <div className="kyc-advanced-options mt">
                <div
                    className="underline-link mt inline-block"
                    onClick={this.toggleAdvanced}>
                    {!advanced ? (
                        <span>
                            {i18n`Show advanced options`}&nbsp;
                            <i className="far fa-chevron-down"></i>
                        </span>
                    ) : (
                        <span>
                            {i18n`Hide advanced options`}&nbsp;
                            <i className="far fa-chevron-up"></i>
                        </span>
                    )}
                </div>

                {advanced && (
                    <div className="box-gray mt">
                        <label className="mt0">
                            {i18n`Custom identity validation description`}
                        </label>

                        {i18n`This is an explanation to the identity validation recipient of why you need to collect their sensitive data`}

                        <textarea
                            className="mt"
                            name="description"
                            rows={8}
                            onChange={this.handleDescriptionChange}
                            value={description || ''}
                        />

                        <Button
                            theme="blue"
                            className="mt"
                            onClick={this.saveCustomKYCDescription}
                            disabled={isInitial}
                            icon="far fa-check"
                            renderIconLeft={true}>
                            {isChangeSaved && isInitial
                                ? i18n`Saved`
                                : i18n`Save as default`}
                        </Button>

                        <Button
                            className="ml"
                            theme="gray"
                            variant="outline"
                            icon="far fa-undo"
                            onClick={this.showRestoreDefaultConfirm}
                            renderIconLeft={true}>
                            {i18n(`Restore default`)}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default connect((state: ReduxState) => ({
    language: state.kyc.__new.language,
    description: state.kyc.__new.description,
}))(KYCAdvancedOptions);
