import lodash from 'lodash';
import { AppThunk } from 'Store';
import { debug } from 'Core';
import { validateSigner as validateSigner2 } from 'Casefiles/utils/casefileValidation';
import {
    getModifiedSigLinesFromCasefile,
    populateSignLinesWithDocumentId,
} from 'Casefiles/utils/signatureLinesFromDocuments';
import { CaseFileEntity } from 'types/CaseFile';
import { SignatureLineEntity } from 'types/Document';
import {
    CASEFILE_ATTACHMENTS_FETCH_SUCCESS,
    CASEFILE_FETCH_FAILURE,
    CASEFILE_FETCH_REQUEST,
    CASEFILE_FETCH_SUCCESS,
    CASEFILE_FOLDER_FETCH_FAILURE,
    CASEFILE_FOLDER_FETCH_REQUEST,
    CASEFILE_FOLDER_FETCH_SUCCESS,
    CASEFILE_FOLDER_RESET,
    CASEFILE_PERSIST_FAILURE,
    CASEFILE_PERSIST_REQUEST,
    CASEFILE_PERSIST_SUCCESS,
    CASEFILE_REFRESH_SUCCESS,
    CASEFILE_UPDATE_LOCAL,
    CLEAR_SIGNATURE_LINES_ACTIVE_AT,
    DOCUMENTS_BACKUP,
    DOCUMENTS_BACKUP_RESTORE,
    DOCUMENT_FETCH_FAILURE,
    DOCUMENT_FETCH_REQUEST,
    DOCUMENT_FETCH_SUCCESS,
    EVENTLOG_FETCH_FAILURE,
    EVENTLOG_FETCH_REQUEST,
    EVENTLOG_FETCH_SUCCESS,
    EXTEND_EXPIRATION_FAILURE,
    EXTEND_EXPIRATION_REQUEST,
    EXTEND_EXPIRATION_SUCCESS,
    PERSIST_SIG_LINE_REQUEST,
    PERSIST_SIG_LINE_SUCCESS,
    PERSIST_SIG_LINE_FAILURE,
    SIGNER_BACKUP_FAILURE,
    SIGNER_BACKUP_REQUEST,
    SIGNER_BACKUP_SUCCESS,
    SIGNER_LOG_FETCH_FAILURE,
    SIGNER_LOG_FETCH_REQUEST,
    SIGNER_LOG_FETCH_SUCCESS,
    SIGNER_PERSIST_REQUEST,
    SIGNER_PERSIST_SUCCESS,
    SIGNER_RESTORE_LOCAL,
    SIGNER_UPDATE_LOCAL,
    UPDATE_SIGNATURE_LINES_ACTIVE_AT,
} from './action-types';
import { formatSSN } from 'utils';
import { SimpleFolderEntity } from 'types/Folder';

export const fetchCasefile = (casefileId): AppThunk => async (
    dispatch,
    _,
    { api: { SigningAPI } }
) => {
    dispatch({ type: CASEFILE_FETCH_REQUEST });
    try {
        const casefile = await SigningAPI.get(`/casefiles/${casefileId}`);

        dispatch({
            type: CASEFILE_FETCH_SUCCESS,
            payload: casefile,
        });

        return casefile;
    } catch (error) {
        debug.error(error);
        dispatch({
            type: CASEFILE_FETCH_FAILURE,
            payload: {
                error: error,
            },
        });
    }
};

export const fetchCasefileEventLog = (
    casefileId,
    refresh = false
): AppThunk => async (dispatch, _, { api: { SigningAPI } }) => {
    if (refresh === false) {
        dispatch({ type: EVENTLOG_FETCH_REQUEST });
    }

    try {
        const response = await SigningAPI.get(
            `/v2/casefiles/${casefileId}/history`
        );

        dispatch({
            type: EVENTLOG_FETCH_SUCCESS,
            payload: response,
        });
    } catch (error) {
        debug.error(error);
        dispatch({
            type: EVENTLOG_FETCH_FAILURE,
            payload: {
                error: error,
            },
        });
    }
};

export const backupSigner = (signerId: number): AppThunk => async (
    dispatch,
    getState
) => {
    dispatch({ type: SIGNER_BACKUP_REQUEST, payload: signerId });

    try {
        // Get current document data to merge with extra metadata
        const reduxState = getState();
        const signerData = reduxState.caseFileDetails.casefile.data.signers.find(
            (s) => s.id === signerId
        );
        const casefileData = reduxState.caseFileDetails.casefile.data;
        const { errors } = validateSigner2(signerData, casefileData);

        dispatch({
            type: SIGNER_BACKUP_SUCCESS,
            payload: {
                __initial: signerData,
                ...signerData,
                id: signerId,
                validation: errors,
            },
        });
    } catch (error) {
        debug.error(error);
        dispatch({
            type: SIGNER_BACKUP_FAILURE,
            payload: {
                id: signerId,
                error: error,
            },
        });
    }
};

export const backupDocuments = (): AppThunk => async (dispatch) => {
    dispatch({ type: DOCUMENTS_BACKUP });
};

export const restoreDocuments = (): AppThunk => async (dispatch) => {
    dispatch({ type: DOCUMENTS_BACKUP_RESTORE });
};

export const fetchSignerEventLog = (
    signerId: number,
    caseFileId: number
): AppThunk => async (dispatch, _, { api: { SigningAPI } }) => {
    dispatch({ type: SIGNER_LOG_FETCH_REQUEST, payload: signerId });

    try {
        // Get current document data to merge with extra metadata
        const eventLog = await SigningAPI.get(
            `/casefiles/${caseFileId}/signers/${signerId}/log`
        );

        dispatch({
            type: SIGNER_LOG_FETCH_SUCCESS,
            payload: {
                id: signerId,
                eventLog,
            },
        });
    } catch (error) {
        debug.error(error);
        dispatch({
            type: SIGNER_LOG_FETCH_FAILURE,
            payload: {
                id: signerId,
                error: error,
            },
        });
    }
};

export const fetchCasefileAttachments = (
    casefile: CaseFileEntity
): AppThunk => async (dispatch, _, { api: { SigningAPI } }) => {
    try {
        const [firstSigner] = casefile.signers;

        if (!firstSigner) {
            return;
        }

        const files = await SigningAPI.get(`/signers/${firstSigner.id}/files`);

        dispatch({
            type: CASEFILE_ATTACHMENTS_FETCH_SUCCESS,
            payload: files,
        });
    } catch (error) {
        debug.error(error);
    }
};

export const fetchDocument = (documentId): AppThunk => async (
    dispatch,
    getState,
    { api: { SigningAPI } }
) => {
    dispatch({ type: DOCUMENT_FETCH_REQUEST, payload: documentId });

    try {
        // Get current document data to merge with extra metadata
        const reduxState = getState();
        const documentData = reduxState.caseFileDetails.casefile.data.documents.find(
            (d) => d.id === documentId
        );

        const { urls, total } = await getDocumentThumbnail(
            documentId,
            SigningAPI
        );
        const [thumbnail] = urls;

        dispatch({
            type: DOCUMENT_FETCH_SUCCESS,
            payload: {
                ...documentData,
                id: documentId,
                thumbnail: thumbnail,
                pageCount: total,
            },
        });
    } catch (error) {
        debug.error(error);

        dispatch({
            type: DOCUMENT_FETCH_FAILURE,
            payload: {
                id: documentId,
                error: error,
            },
        });
    }
};

export const resetCasefileFolder = (): AppThunk => async (dispatch) =>
    dispatch({ type: CASEFILE_FOLDER_RESET });

export const fetchCasefileFolder = (casefileId): AppThunk => async (
    dispatch,
    _,
    { api: { SigningAPI } }
) => {
    dispatch({ type: CASEFILE_FOLDER_FETCH_REQUEST });
    try {
        /**
         * NOTE: the endpoint returns an array of folders with only one element
         * https://sandbox.penneo.com/api/docs/#/Case%20files/get_api__version__casefiles__caseFileId__folders
         */
        const folders: SimpleFolderEntity[] = await SigningAPI.get(
            `/casefiles/${casefileId}/folders`
        );

        dispatch({
            type: CASEFILE_FOLDER_FETCH_SUCCESS,
            payload: folders[0],
        });
    } catch (error) {
        debug.error(error);
        dispatch({
            type: CASEFILE_FOLDER_FETCH_FAILURE,
            payload: {
                error: error,
            },
        });
    }
};

export const restoreSigner = (signerId: number) => ({
    type: SIGNER_RESTORE_LOCAL,
    payload: signerId,
});

export const persistSignatureLines = (signerId: number): AppThunk => async (
    dispatch,
    getState,
    { api: { SigningAPI } }
) => {
    dispatch({ type: PERSIST_SIG_LINE_REQUEST });

    try {
        const modifiedSignatureLines = getModifiedSigLinesFromCasefile(
            signerId,
            getState().caseFileDetails.casefile.data
        );

        const sigLinesWithDocumentId = populateSignLinesWithDocumentId(
            modifiedSignatureLines,
            getState().caseFileDetails.casefile.data
        );

        const promises = sigLinesWithDocumentId.map((modifiedSignatureLine) => {
            const documentId = modifiedSignatureLine.documentId;
            const signatureLineId = modifiedSignatureLine.id;
            const activeAtDate = modifiedSignatureLine.activeAt || null;

            return SigningAPI.put(
                `/documents/${documentId}/signaturelines/${signatureLineId}`,
                { activeAt: activeAtDate }
            );
        });

        await Promise.all(promises);
        dispatch({ type: PERSIST_SIG_LINE_SUCCESS });
    } catch (err) {
        dispatch({ type: PERSIST_SIG_LINE_FAILURE });

        return Promise.reject();
    }
};

export const persistSigner = (
    casefileId: number,
    signerId: number
): AppThunk => async (dispatch, getState, { api: { SigningAPI } }) => {
    dispatch({ type: SIGNER_PERSIST_REQUEST });

    try {
        const reduxState = getState();
        const signer = reduxState.caseFileDetails.signers[signerId];

        const signerPayload = lodash.pick(signer, [
            'name',
            'socialSecurityNumberPlain',
            'vatin',
            'onBehalfOf',
            'ssnType',
        ]);

        if (signerPayload.socialSecurityNumberPlain) {
            signerPayload.socialSecurityNumberPlain = formatSSN(
                signerPayload.socialSecurityNumberPlain,
                signer.ssnType
            );
        } else {
            signerPayload.ssnType = 'legacy';
        }

        const signingRequestPayload = lodash.pick(signer.signingRequest, [
            'accessControl',
            'email',
            'emailSubject',
            'emailText',
            'enableInsecureSigning',
            'insecureSigningMethods',
            'reminderInterval',
        ]);

        // Update requests
        await SigningAPI.put(
            `/casefiles/${casefileId}/signers/${signer.id}`,
            signerPayload
        );
        await SigningAPI.put(
            `/signingrequests/${signer.signingRequest.id}`,
            signingRequestPayload
        );

        // Fetch casefile data to reconcile state
        const casefile = await SigningAPI.get(
            `/casefiles/${casefileId}/details`
        );

        dispatch({
            type: CASEFILE_REFRESH_SUCCESS,
            payload: casefile,
        });

        const updatedSigner = casefile.signers.find((s) => s.id === signer.id);

        await dispatch({
            type: SIGNER_PERSIST_SUCCESS,
            payload: {
                ...updatedSigner,
            },
        });

        await dispatch(fetchSignerEventLog(signerId, casefileId));

        return Promise.resolve();
    } catch (error) {
        return Promise.reject();
    }
};

export const updateCasefile = (data: any = {}) => ({
    type: CASEFILE_UPDATE_LOCAL,
    payload: data,
});

export const updateSignatureLinesActiveAt = (
    activeAtDate: number,
    originalRoleName: string,
    signerId: number
): AppThunk => (dispatch, getState) => {
    const signatureLines = lodash.flatten(
        lodash
            .values(getState().caseFileDetails.casefile.data.documents)
            .map((document) => {
                return document.signatureLines;
            })
    );

    const signatureLinesToUpdate = signatureLines.filter(
        (signatureLine: SignatureLineEntity) => {
            return (
                signatureLine.signerId === signerId &&
                signatureLine.signerTypeOriginalRole === originalRoleName
            );
        }
    );

    dispatch({
        type: UPDATE_SIGNATURE_LINES_ACTIVE_AT,
        payload: { signatureLinesToUpdate, activeAt: activeAtDate },
    });
};

export const clearSignatureLinesActiveAt = (
    originalRoleName: string,
    signerId: number
): AppThunk => (dispatch, getState) => {
    const signatureLines = lodash.flatten(
        Object.values(getState().caseFileDetails.casefile.data.documents).map(
            (document) => {
                return document.signatureLines;
            }
        )
    );

    const signatureLinesToUpdate = signatureLines.filter((signatureLine) => {
        return (
            signatureLine.signerId === signerId &&
            signatureLine.signerTypeOriginalRole === originalRoleName
        );
    });

    dispatch({
        type: CLEAR_SIGNATURE_LINES_ACTIVE_AT,
        payload: { signatureLinesToUpdate },
    });
};

export const updateSigner = (signerId: number, data: any): AppThunk => (
    dispatch,
    getState
) => {
    const reduxState = getState();
    const signer = reduxState.caseFileDetails.signers[signerId];
    const signerBasicData =
        data.hasOwnProperty('name') ||
        data.hasOwnProperty('socialSecurityNumberPlain') ||
        data.hasOwnProperty('ssnType') ||
        data.hasOwnProperty('vatin') ||
        data.hasOwnProperty('onBehalfOf');

    let updatedSigner = {};

    if (signerBasicData) {
        updatedSigner = {
            ...signer,
            ...data,
        };
    } else {
        updatedSigner = {
            ...signer,
            signingRequest: {
                ...signer.signingRequest,
                ...data,
            },
        };
    }

    const casefileData = reduxState.caseFileDetails.casefile.data;
    const { errors } = validateSigner2(updatedSigner, casefileData);

    const finalSigner = {
        ...updatedSigner,
        validation: errors,
    };

    dispatch({ type: SIGNER_UPDATE_LOCAL, payload: finalSigner });
};

export const sendSigningRequest = (
    casefileId: number,
    signerId: number
): AppThunk => async (dispatch, _, { api: { SigningAPI } }) => {
    try {
        await SigningAPI.post(
            `/casefiles/${casefileId}/signers/${signerId}/notify`
        );
        await dispatch(fetchSignerEventLog(signerId, casefileId));

        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
};

// If a signer has rejected to sign, the rejection can be reverted
export const reactivateSigner = (
    casefileId: number,
    signerId: number
): AppThunk => async (dispatch, _, { api: { SigningAPI } }) => {
    try {
        await SigningAPI.post(
            `/casefiles/${casefileId}/signers/${signerId}/reactivate`
        );

        const casefile = await SigningAPI.get(
            `/v2/casefiles/${casefileId}/details`
        );

        dispatch({
            type: CASEFILE_REFRESH_SUCCESS,
            payload: casefile,
        });

        await dispatch(fetchSignerEventLog(signerId, casefileId));

        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteSigner = (
    casefileId: number,
    signerId: number
): AppThunk => async (dispatch, getState, { api: { SigningAPI } }) => {
    try {
        await SigningAPI.delete(`/casefiles/${casefileId}/signers/${signerId}`);
        const casefile = await SigningAPI.get(
            `/v2/casefiles/${casefileId}/details`
        );

        dispatch({
            type: CASEFILE_REFRESH_SUCCESS,
            payload: casefile,
        });

        const updatedSigner = casefile.signers.find((s) => s.id === signerId);

        return dispatch({
            type: SIGNER_PERSIST_SUCCESS,
            payload: {
                __initial: updatedSigner,
                ...updatedSigner,
            },
        });
    } catch (error) {
        dispatch({ type: 'SIGNER_DELETE_FAILURE' });
        throw error;
    }
};

export const createSigningLink = (signerId: number): AppThunk => async (
    _,
    getState,
    { api: { SigningAPI } }
) => {
    const reduxState = getState();
    const requestId =
        reduxState.caseFileDetails.signers[signerId].signingRequest.id;

    return SigningAPI.patch(`/signingrequests/${requestId}/link`);
};

export const extendExpiration = (expireAt: number | null): AppThunk => async (
    dispatch,
    getState,
    { api: { SigningAPI } }
) => {
    dispatch({ type: EXTEND_EXPIRATION_REQUEST });

    const reduxState = getState();
    const { id } = reduxState.caseFileDetails.casefile.data;
    const payload = {
        expirationDate: expireAt,
    };

    try {
        await SigningAPI.post(`/casefiles/${id}/extend-expiration`, payload);
        await dispatch({ type: EXTEND_EXPIRATION_SUCCESS });

        return Promise.resolve();
    } catch (error) {
        dispatch({
            type: EXTEND_EXPIRATION_FAILURE,
            payload: {
                error,
            },
        });

        return Promise.reject(error);
    }
};

export const persistCasefile = (): AppThunk => async (
    dispatch,
    getState,
    { api: { SigningAPI } }
) => {
    dispatch({ type: CASEFILE_PERSIST_REQUEST });

    const reduxState = getState();
    const { data } = reduxState.caseFileDetails.casefile;

    let payload = lodash.pick(data, [
        'disableEmailAttachments',
        'disableNotificationsOwner',
        'documentDisplayMode',
        'expireAt',
        'language',
        'metaData',
        'reference',
        'sendAt',
        'sensitiveData',
        'signOnMeeting',
        'title',
        'visibilityMode',
    ]);

    try {
        await SigningAPI.put(`/casefiles/${data.id}`, payload);
        await dispatch({ type: CASEFILE_PERSIST_SUCCESS });
        await dispatch(fetchCasefileEventLog(data.id, true));

        return Promise.resolve();
    } catch (error) {
        dispatch({
            type: CASEFILE_PERSIST_FAILURE,
            payload: {
                error: error,
            },
        });

        return Promise.reject(error);
    }
};

const getDocumentThumbnail = async (documentId, SigningAPI) => {
    const jitter = 500;
    const limit = 5;

    let delay = 3000;
    let iteration = 1;
    let response: any = false;

    do {
        // If limit of executions is reached, exit promise.
        if (iteration > limit) {
            return Promise.reject({ error: 'DOCUMENT_THUMBNAIL_TIMEOUT' });
        }

        // Increase the delay of execution (for subsequent intervals)
        delay = iteration === 0 ? 0 : delay + jitter * iteration;

        await wait(delay);
        response = await SigningAPI.get(`/documents/${documentId}/images`, {
            limit: 1,
        });

        iteration = iteration + 1;
    } while (response === false);

    return response;
};

const wait = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
