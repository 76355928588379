import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router';
import { i18n } from 'Language';
import lodash from 'lodash';
import { DateTime } from 'luxon';
import { CustomerEntity } from 'types/Customer';
import Tooltip from 'Common/components/Tooltip';
import { connect } from 'react-redux';
import { AppDispatch, ReduxState } from 'Store';
import { UserSettingKeys, UserSettings } from 'Settings/redux/types';
import { updateUserSettings } from 'Settings/redux/actions';
import {
    DASHBOARD_NAVIGATION,
    TOP_NAVIGATION,
    MIDDLE_NAVIGATION,
    BOTTOM_NAVIGATION,
    WHATS_NEW_NAVIGATION,
    SIGNER_NAVIGATION,
    NavigationItem,
    WHATS_NEW_WORDPRESS_URL,
    SIGNER_NAVIGATION_BOTOM,
    SIGNER_PROFILE_NAVIGATION,
} from 'Constants';
import analytics from 'Common/Analytics';
import classnames from 'classnames';
import ModalCreateNew from 'Common/components/ModalCreateNew';
import Button from 'Common/components/Button';
import WhatsNew from 'Common/components/SidebarNavigation/WhatsNew';
import { Settings } from '../../../Settings/redux/types';
import { WordPressPost } from './types';
import uniqid from 'uniqid';
import { Float } from 'Common/components/Float/Float';
import {
    ChevronDownIcon,
    BriefcaseIcon,
    TrashIcon,
    QuestionMarkCircleIcon,
    Cog6ToothIcon,
    BuildingOffice2Icon,
    DocumentTextIcon,
    IdentificationIcon,
    RocketLaunchIcon,
    EllipsisHorizontalIcon,
    EllipsisVerticalIcon,
    ChartPieIcon,
    ArrowRightEndOnRectangleIcon,
} from '@heroicons/react/24/outline';
import {
    BriefcaseIcon as BriefcaseIconFilled,
    TrashIcon as TrashIconFilled,
    QuestionMarkCircleIcon as QuestionMarkCircleIconFilled,
} from '@heroicons/react/24/solid';

// @todo: Split the breadcrumb reducer from archive reducer
import './sidebar-navigation.scss';
import axios from 'axios';
import { userCanCreate } from 'Common/utils/userPermissions';
import UITooltip from '../Vega/Tooltip';
import UIButton from '../Vega/Button';
import Analytics from 'Common/Analytics';
import useScrollbarWidth from 'Common/hooks/useScrollbarWidth';
import useHasScrollbar from 'Common/hooks/useHasScrollbar';
// tooltip styles needs to be compiled and cannot be all dynamic
const baseBorderColor = '#16bc65';
const baseBorderSignerColor = '#008BFF';

const tooltipCls =
    'border-r rounded-tr rounded-br whitespace-nowrap bg-[#03224c]';

const afterTriangle = ` 
after:content-[''] after:absolute after:top-[50%] after:left-[-9px] after:translate-y-[-50%]
after:w-[0] after:h-[0] after:border-solid after:border-t-[6px] after:border-r-[6px] 
after:border-b-[6px] after:border-l-[0] after:border-t-transparent after:border-b-transparent after:border-l-transparent`;

const generateTooltipStyles = (borderColor: string) => ({
    borderLeft: `3px solid ${borderColor}`,
});

const tooltipElemCls = `p-[12px] cursor-pointer font-bold inline-block text-[9pt] text-light hover:text-white relative`;
const tooltipGreenTriangleElemCls = `cursor-pointer font-bold inline-block text-[9pt] text-light hover:text-white relative ${afterTriangle} after:border-r-[#16bc65]`;
const tooltipBlueTriangleElemCls = `cursor-pointer font-bold inline-block text-[9pt] text-light hover:text-white relative ${afterTriangle} after:border-r-[#008BFF]`;

const getIcon = (iconName: string, useOutline: boolean) => {
    const cls =
        'w-[24px] h-[24px] gap-0 border-t-[1.5px] border-transparent absolute';

    switch (iconName) {
        case 'briefcase':
            if (useOutline) {
                return <BriefcaseIcon className={cls} />;
            }

            return <BriefcaseIconFilled className={cls} />;
        case 'compass':
            return <ChartPieIcon className={cls} />;
        case 'id-card-alt':
            return <IdentificationIcon className={cls} />;
        case 'cog':
            return <Cog6ToothIcon className={cls} />;
        case 'building':
            return <BuildingOffice2Icon className={cls} />;
        case 'file-alt':
            return <DocumentTextIcon className={cls} />;
        case 'trash-alt':
            if (useOutline) {
                return <TrashIcon className={cls} />;
            }

            return <TrashIconFilled className={cls} />;
        case 'question-circle':
            if (useOutline) {
                return <QuestionMarkCircleIcon className={cls} />;
            }

            return <QuestionMarkCircleIconFilled className={cls} />;
        case 'satellite-dish':
            return <RocketLaunchIcon className={cls} />;
        default:
            return null;
    }
};

export type Props = {
    branding: any;
    customer?: CustomerEntity;
    dispatch: AppDispatch;
    mobile: boolean;
    mobileMenuOpen: boolean;
    onMenuItemClick: Function;
    settings: Settings;
    toggleCollapse: any;
    whatsNewUserSetting: UserSettings[UserSettingKeys.whatsNew];
    collapsed?: boolean;
    routes?: any;
    title?: string;
    user?: any;
};

export const SidebarNavigation = (props: Props) => {
    const { collapsed, customer, user } = props;
    const scrollbarWidth = useScrollbarWidth();
    const { ref, hasScrollbar } = useHasScrollbar();
    const [open, setOpen] = useState<number | null>(null);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [whatsNewActive, setWhatsNewActive] = useState(false);
    const [wordPressData, setWordPressData] = useState<WordPressPost[]>([]);

    useEffect(() => {
        const fetchWordpressData = async () => {
            try {
                const { data } = await axios.get(WHATS_NEW_WORDPRESS_URL);

                setWordPressData(data);
            } catch (error) {
                setWordPressData([]);
            }
        };

        if (customer) {
            fetchWordpressData();
        }
    }, []);

    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        item: NavigationItem
    ) => {
        // If trying to open the link in a new tab...
        // @see http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
        if (e.metaKey || e.ctrlKey || e.button === 1) {
            return;
        }

        // Stop any link (<a>) default behaviour
        if (item.href) {
            e.preventDefault();
        }

        const { collapsed } = props;

        // Make top level items non-clickable on collapsed mode
        if (collapsed && item.id && item.children) {
            return false;
        }

        if (item.children) {
            setOpen(item.id ? (item.id === open ? null : item.id) : null);
        }

        if (item.href && item.href.url) {
            // Track navigation before redirecting user
            analytics.track('navigation - sidebar', {
                route: item.href.url,
            });

            return item.href.external
                ? window.open(item.href.url)
                : (window.location = item.href.url as any);
        }

        if (!item.route) {
            return false;
        }

        props.onMenuItemClick();

        // Reset manually openeed menu items
        setOpen(null);

        closeWhatsNew(e);

        // Track navigation
        analytics.track('navigation - sidebar', {
            route: item.route,
        });
    };

    const handleToggleCollapse = () => {
        setOpen(null); // Reset open menu.
        props.toggleCollapse();
    };

    const handleCTAButton = () => {
        setOpenCreateDialog(true);

        setWhatsNewActive(false);

        props.onMenuItemClick();

        analytics.track('create new', {
            source: 'sidebar button',
        });
    };

    const buildRoute = (item: NavigationItem) => {
        const { user } = props;
        let params = {};

        if (user) {
            params = {
                userId: user.ids,
                customerId: user.customerIds ? user.customerIds[0] : 0,
            };
        }

        return {
            name: item.route,
            params,
        };
    };

    const getFilteredMenuItems = (
        items: NavigationItem[]
    ): NavigationItem[] => {
        const { user, customer, settings } = props;
        const { mobile } = props;

        // getFilteredMenuItems is only being called with a customer, so we can assume that the user is a customer
        return (
            items
                // Evaluate access per item
                .filter(
                    (item) =>
                        typeof item.show === 'undefined' ||
                        (customer && item.show(user, customer, settings))
                )
                // When not on mobile viewport, remove "mobile only" items
                .filter((item) => (!mobile ? !item.mobileOnly : true))
        );
    };

    /**
     * Determines if this menu item has active children
     */
    const hasItemAnActiveChild = (itemChildren: NavigationItem[]): boolean => {
        const { routes } = props;
        const routeNames = routes.map((r) => r.name);
        const childRoutes = itemChildren.map((r) => r.route);

        return lodash.intersection(routeNames, childRoutes).length > 0;
    };

    const isItemOpen = (item: NavigationItem): boolean => {
        return open === item.id;
    };

    const isItemRouteActive = (item: NavigationItem): boolean => {
        /* const { whatsNewActive } = state; */
        const { routes } = props;

        if (item.isWhatsNew && whatsNewActive) {
            return true;
        }

        return !!routes.filter((r) => !!r.name && r.name === item.route)[0];
    };

    const renderChildrenIndicator = (
        item: NavigationItem,
        hasActiveChild: boolean
    ) => {
        const {
            collapsed,
            branding: { highlightColor },
        } = props;
        const isOpenOrActive = isItemOpen(item) || hasActiveChild;
        const cls = 'w-[24px] h-[24px] align-middle';

        let color: string;

        if (isOpenOrActive) {
            if (customer) {
                if (!hasActiveChild) {
                    color = 'text-[#FFF]';
                } else {
                    color = `text-[${highlightColor || '#16BC65'}]`;
                }
            } else {
                color = 'text-[#16BC65]';
            }
        } else {
            color = hasActiveChild ? 'text-[#FFF]' : 'text-[#999]';
        }

        return (
            <span
                className={`relative ml-auto ${
                    collapsed ? '' : 'mr-0 ml-auto'
                }${color} `}>
                {collapsed ? (
                    <EllipsisVerticalIcon className="w-[18px] h-[18px] absolute -top-[8px]" />
                ) : isOpenOrActive ? (
                    <ChevronDownIcon className={cls} />
                ) : (
                    <EllipsisHorizontalIcon className={cls} />
                )}
            </span>
        );
    };

    const renderMenuItemTitle = (
        item: NavigationItem,
        hasActiveChild: boolean,
        controls?: string,
        hasOpenedChildren: boolean = false
    ) => {
        const {
            branding: { highlightColor },
            collapsed,
            customer,
        } = props;
        const onClick = (e) => handleClick(e, item);
        const isActive = isItemRouteActive(item);

        const isRouteOpenOrActive = isItemOpen(item) || hasActiveChild;
        const resolveColor = (isIcon?: boolean) => {
            if (isActive) {
                return customer
                    ? !isIcon || hasActiveChild
                        ? 'text-[#FFF]'
                        : `text-[${highlightColor || '#16BC65'}]`
                    : 'text-[#1C56E7]';
            }

            if (isRouteOpenOrActive) {
                return customer ? 'text-[#FFF]' : 'text-[#1C56E7]';
            }

            return customer
                ? hasActiveChild
                    ? 'text-[#FFF]'
                    : 'text-[#999]'
                : 'text-[#04224C]';
        };

        const iconColor = resolveColor(true);
        const textColor = resolveColor();

        const hoverTextColor = customer
            ? 'group-hover:text-[#FFF]'
            : 'group-hover:text-[#2A30C7]';

        const iconAndTitle = (
            <>
                {(item.route || item.href) && (
                    <div
                        className={`absolute bg-transparent -top-1/4 -left-1/3 ${
                            collapsed ? 'w-[200%]' : 'w-[100%]'
                        } h-[100%]`}></div>
                )}
                {item.icon && (
                    <div
                        className={`w-[24px] h-[24px] inline-flex flex-shrink-0 ${iconColor} ${hoverTextColor}`}>
                        {getIcon(
                            item.icon,
                            customer
                                ? true
                                : isActive || isRouteOpenOrActive
                                ? false
                                : true
                        )}
                    </div>
                )}
                <span
                    className={`
    h-[24px] ml-2 text-sm leading-6 font-semibold whitespace-nowrap overflow-hidden
    ${
        collapsed
            ? 'opacity-0 max-w-0 overflow-hidden hidden'
            : 'opacity-100 max-w-full'
    }
    flex-shrink-0
  `}>
                    {i18n(item.title)}
                </span>
            </>
        );

        const cls = `group flex items-center ${textColor} ${hoverTextColor} appearance-none border-0 bg-transparent relative no-underline transition-all duration-300`;

        if (item.route) {
            return (
                <Link
                    className={cls}
                    to={buildRoute(item)}
                    onClick={onClick}
                    title={i18n(item.title)}>
                    {iconAndTitle}
                </Link>
            );
        }

        if (item.href) {
            return (
                <a
                    className={classnames(cls)}
                    href={item.href.url}
                    onClick={onClick}
                    title={i18n(item.title)}>
                    {iconAndTitle}
                </a>
            );
        }

        const ariaAttrs =
            item.children && controls
                ? {
                      ...{
                          'aria-expanded': hasOpenedChildren,
                          'aria-controls': `${controls}`,
                          role: 'switch',
                          'aria-checked': hasOpenedChildren,
                      },
                  }
                : {};

        const isWhatsNewItem = item.id === 9;

        return (
            <button
                className={classnames(isWhatsNewItem ? '' : 'w-full', cls)}
                style={
                    isWhatsNewItem
                        ? { marginLeft: '-6px', marginTop: '-6px' }
                        : { marginLeft: '-6px' }
                }
                onClick={onClick}
                {...ariaAttrs}
                {...item.props}>
                {iconAndTitle}
                {item.children && renderChildrenIndicator(item, hasActiveChild)}
            </button>
        );
    };

    const renderMenuItem = (item: NavigationItem, isChild?: boolean) => {
        const { collapsed, branding } = props;

        const visibleChildren = item.children
            ? getFilteredMenuItems(item.children)
            : false;
        const hasActiveChild =
            visibleChildren && hasItemAnActiveChild(visibleChildren);
        const isRouteActive = isItemRouteActive(item);
        const isOpen = isItemOpen(item);
        const showChildren = collapsed
            ? visibleChildren && isOpen
            : (visibleChildren && hasActiveChild) || isOpen;
        const includesSubItems = item.id === 7 || item.id === 6;
        const isWhatsNewItem = item.id === 9;

        const menuItemClassName = classnames(
            'group cursor-pointer p-[10px] pb-0 rounded-[3px] border-l-2 border-l-solid border-r-2 border-r-solid border-transparent font-sans font-semibold leading-[44px] relative transition-all duration-300',
            {
                'bg-[#E1F1FF]': isRouteActive && !customer,
                'bg-transparent': !isRouteActive && !customer,
                'bg-opacity-10 bg-[#FFEB3B]':
                    (isRouteActive && customer) ||
                    (customer && hasActiveChild && collapsed),
                'h-[44px]': !showChildren,
                'border-l-[#16bc65]':
                    !collapsed &&
                    isRouteActive &&
                    customer &&
                    !branding.highlightColor,
                'hover:border-l-[#fff]':
                    !collapsed && !isRouteActive && isChild,
                'hover:border-opacity-[50]':
                    !collapsed && !isRouteActive && !isChild,
                'hover:bg-[#E1F1FF]': !customer && !isRouteActive,
                'hover:bg-[#B8DCFF]': !customer && isRouteActive,
                'p-[10px]': isChild,
            }
        );

        const shouldShowTooltip = collapsed;
        const borderColor = customer ? baseBorderColor : baseBorderSignerColor;
        const tooltipWithTriangle = customer
            ? tooltipGreenTriangleElemCls
            : tooltipBlueTriangleElemCls;

        const subItemsTooltip = (
            <div
                className={tooltipCls}
                style={generateTooltipStyles(borderColor)}>
                <div
                    className={`flex flex-col ${tooltipWithTriangle} p-0 after:left-[-5px]`}>
                    {visibleChildren &&
                        visibleChildren.map((item, index) => {
                            const isRouteActive = isItemRouteActive(item);

                            return (
                                <Link
                                    to={buildRoute(item)}
                                    onClick={closeWhatsNew}
                                    key={index}
                                    className={`${tooltipElemCls} ${
                                        isRouteActive
                                            ? 'bg-white bg-opacity-10'
                                            : ''
                                    }`}
                                    title={i18n(item.title)}>
                                    <span>{i18n(item.title)}</span>
                                </Link>
                            );
                        })}
                </div>
            </div>
        );

        const ariaLabels = isWhatsNewItem
            ? {
                  ...{
                      'aria-expanded': whatsNewActive,
                      'aria-controls': 'whats-new-sidebar',
                  },
              }
            : {};

        const menuItemElement = (
            <li
                id={isWhatsNewItem ? 'whats-new-button-container' : undefined}
                className={menuItemClassName}
                onClick={isWhatsNewItem ? toggleWhatsNew : undefined}
                {...ariaLabels}
                style={
                    isRouteActive
                        ? { borderLeft: '2px solid' }
                        : { borderLeft: '2px solid transparent' }
                }>
                {isWhatsNewItem ? (
                    <>
                        {renderMenuItemTitle(
                            item,
                            hasActiveChild,
                            'whats-new-sidebar',
                            whatsNewActive
                        )}
                        {whatsNewContainsNewPosts() === true && (
                            <div
                                className={`relative top-2 flex h-2.5 w-2.5 ${
                                    collapsed ? '-left-1' : 'left-4'
                                }`}>
                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#16BC65] opacity-70"></span>
                                <span className="relative inline-flex h-2.5 w-2.5 bg-[#47ec97] opacity-30 rounded-full"></span>
                            </div>
                        )}
                    </>
                ) : (
                    renderMenuItemTitle(
                        item,
                        hasActiveChild,
                        `${item.id}-children`,
                        isOpen
                    )
                )}
                {showChildren && visibleChildren && (
                    <nav
                        aria-label={`${item.title} Sub-menu`}
                        id={`${item.id}-children`}>
                        <ul className={`pl-3`}>
                            {collapsed && (
                                <div>
                                    <span className={menuItemClassName}>
                                        {i18n(item.title)}
                                    </span>
                                </div>
                            )}

                            {visibleChildren.map((item) =>
                                renderMenuItem(item, true)
                            )}
                        </ul>
                    </nav>
                )}
            </li>
        );

        if (shouldShowTooltip && includesSubItems) {
            return (
                <div key={`${uniqid()}-${item.id}`}>
                    <Float
                        label={subItemsTooltip}
                        applySafePolygon={true}
                        placement="right"
                        offsetAmount={11}>
                        {menuItemElement}
                    </Float>
                </div>
            );
        }

        return (
            <div key={`${item.id}`}>
                {menuItemElement}{' '}
                {shouldShowTooltip && (
                    <UITooltip placement="right" offset={7}>
                        {i18n(item.title)}
                    </UITooltip>
                )}
            </div>
        );
    };

    const toggleWhatsNew = () => {
        const { dispatch } = props;
        const payload = { lastSeen: new Date() };

        /* Post current timestamp to user settings for keeping track of when the
         * user last looked at the What's new page */
        dispatch(updateUserSettings(UserSettingKeys.whatsNew, payload));

        setWhatsNewActive(!whatsNewActive);
    };

    const closeWhatsNew = (e: SyntheticEvent) => {
        const target = e.target as HTMLElement;

        const targetIsWhatsNewButton =
            target.parentElement?.parentElement?.parentElement?.id ===
            'whats-new-button-container';

        if (targetIsWhatsNewButton) {
            return;
        }

        setWhatsNewActive(false);
    };

    const logout = () => {
        Analytics.track('log out', { origin: 'sidebar' });
        window.location.href = '/logout';
    };

    const whatsNewContainsNewPosts = () => {
        const { whatsNewUserSetting } = props;

        if (wordPressData.length <= 0) {
            return false;
        }

        const getNewestPost = (a: WordPressPost, b: WordPressPost) => {
            const postADateLuxon = DateTime.fromISO(a.date).setZone('UTC', {
                keepLocalTime: true,
            });

            const postBDateLuxon = DateTime.fromISO(b.date).setZone('UTC', {
                keepLocalTime: true,
            });

            const diff = postADateLuxon.diff(postBDateLuxon).milliseconds;

            if (diff > 0) {
                return a;
            } else {
                return b;
            }
        };

        const newestPost = wordPressData.reduce(getNewestPost);

        const newestPostDateLuxon = DateTime.fromISO(newestPost.date).setZone(
            'UTC',
            {
                keepLocalTime: true,
            }
        );

        const lastSeenDateLuxon = DateTime.fromISO(
            whatsNewUserSetting.lastSeen
        );

        const diff = newestPostDateLuxon.diff(lastSeenDateLuxon).milliseconds;

        if (diff < 0) {
            return false;
        } else {
            return true;
        }
    };

    const sidebarClassNames = classnames('sidebar-navigation', {
        collapsed,
        mobile: props.mobile,
        open: props.mobileMenuOpen,
    });

    const isCustomerActive = customer?.active || false;
    const { mobile } = props;

    const dashboardItem = customer
        ? getFilteredMenuItems(DASHBOARD_NAVIGATION)
        : [];
    const topItems = customer
        ? getFilteredMenuItems(TOP_NAVIGATION)
        : SIGNER_NAVIGATION;
    const middleItems = customer
        ? getFilteredMenuItems(MIDDLE_NAVIGATION)
        : mobile
        ? SIGNER_PROFILE_NAVIGATION.concat(SIGNER_NAVIGATION_BOTOM)
        : SIGNER_PROFILE_NAVIGATION;
    const bottomItems = customer
        ? getFilteredMenuItems(BOTTOM_NAVIGATION)
        : mobile
        ? []
        : SIGNER_NAVIGATION_BOTOM;
    const whatsNewItem = customer
        ? getFilteredMenuItems(WHATS_NEW_NAVIGATION)
        : [];

    const isStartWorkEnabled = isCustomerActive && userCanCreate(user);
    const backgroundColor = customer ? '#03224c' : '#F5F9FF';
    const sidebarMarginRight =
        !mobile && hasScrollbar ? `${scrollbarWidth ?? 0}px` : '0';

    const dashboardMenuItems = useMemo(
        () => dashboardItem.map((item) => renderMenuItem(item)),
        [dashboardItem, renderMenuItem]
    );

    const topMenuItems = useMemo(
        () => topItems.map((item) => renderMenuItem(item)),
        [topItems, renderMenuItem]
    );

    const middleMenuItems = useMemo(
        () => middleItems.map((item) => renderMenuItem(item)),
        [middleItems, renderMenuItem]
    );
    const bottomMenuItems = useMemo(
        () => bottomItems.map((item) => renderMenuItem(item)),
        [bottomItems, renderMenuItem]
    );

    return (
        <div>
            {openCreateDialog && (
                <ModalCreateNew
                    onClose={() => setOpenCreateDialog(false)}
                    user={user}
                />
            )}
            <nav
                ref={ref}
                className={sidebarClassNames}
                id="nav"
                aria-label={i18n`Sidebar`}
                style={
                    customer
                        ? {
                              background: backgroundColor,
                              marginRight: `${sidebarMarginRight}`,
                          }
                        : {
                              background: backgroundColor,
                              marginRight: `${sidebarMarginRight}`,
                              boxShadow:
                                  'inset -2px 0px 0 -1px rgba(0, 0, 0, 0.1)',
                          }
                }>
                {customer && (
                    <button
                        style={
                            !collapsed && hasScrollbar
                                ? { left: `${220 - (scrollbarWidth || 0)}px` }
                                : {}
                        }
                        className="collapse-tab"
                        onClick={() => handleToggleCollapse()}>
                        <Tooltip
                            className="collapse-tab-tooltip"
                            text={
                                <span>
                                    <span>
                                        {collapsed
                                            ? i18n`Open sidebar`
                                            : i18n`Collapse sidebar`}
                                    </span>
                                    <span className="shortcut-key">
                                        Ctrl + /
                                    </span>
                                </span>
                            }>
                            <i
                                className={`collapse-tab-icon fas fa-chevron-${
                                    collapsed ? 'right' : 'left'
                                }`}></i>
                        </Tooltip>
                    </button>
                )}
                {customer ? (
                    collapsed ? (
                        <>
                            <Button
                                id="create-new-btn"
                                renderIconLeft={true}
                                className="cta-main"
                                theme="blue"
                                icon="far fa-plus-circle"
                                disabled={!isStartWorkEnabled}
                                onClick={() => handleCTAButton()}
                                data-testid="create-new-btn"
                                aria-describedby="create-details"
                                fullWidth>
                                {!collapsed && i18n`Create new`}
                            </Button>
                            <UITooltip placement="right" offset={7}>
                                {i18n('Create new')}
                            </UITooltip>
                        </>
                    ) : (
                        <Button
                            id="create-new-btn"
                            size="large"
                            renderIconLeft={true}
                            className="cta-main"
                            theme="blue"
                            icon="far fa-plus-circle"
                            disabled={!isStartWorkEnabled}
                            onClick={() => handleCTAButton()}
                            data-testid="create-new-btn"
                            fullWidth
                            aria-describedby="create-details">
                            {!collapsed && i18n`Create new`}
                        </Button>
                    )
                ) : null}

                {customer && (
                    <p
                        className="sr-only"
                        id="create-details"
                        hidden>{i18n`Create new casefile, webform, or identity validation document`}</p>
                )}

                <div
                    className={`overflow-visible ${
                        mobile && !customer
                            ? 'h-[calc(100%-25px)]'
                            : 'h-[calc(100%-55px)]'
                    } flex flex-col`}>
                    {customer && <ul className="mt-8">{dashboardMenuItems}</ul>}
                    <ul
                        className={`${
                            mobile && !customer
                                ? 'mt-0 0 border-solid border-l-0 border-t-0 border-r-0 border-b-1 border-[#D1D5DB] pb-10'
                                : ''
                        }`}>
                        {topMenuItems}
                    </ul>
                    {(customer || mobile) && (
                        <ul className={`${mobile ? 'mt-4' : 'mt-8'}`}>
                            {middleMenuItems}
                        </ul>
                    )}
                    <ul className="mt-auto"></ul>
                    <ul className="mt-auto">
                        {bottomMenuItems}
                        {customer &&
                            whatsNewItem.map((item) => renderMenuItem(item))}
                    </ul>
                    {mobile && (
                        <div
                            data-testid="logout-btn-wrapper"
                            className={`${mobile ? 'mt-auto' : ''}`}>
                            <UIButton
                                priority="secondary"
                                fullWidth
                                type={customer ? 'accent' : 'neutral'}
                                onClick={logout}>
                                {i18n`Logout`}
                                <span
                                    slot="rightIcon"
                                    className="block w-[24px] h-[24px]">
                                    <ArrowRightEndOnRectangleIcon className="w-[24px] h-[24px]" />
                                </span>
                            </UIButton>
                        </div>
                    )}
                </div>
                <Link to="debug" className="sidebar-navigation-app-version">
                    Debug
                </Link>
            </nav>
            {customer && (
                <WhatsNew
                    active={whatsNewActive}
                    wordPressData={wordPressData}
                    toggleWhatsNew={toggleWhatsNew}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    whatsNewUserSetting: state.settings.data.user.whatsNew,
});

export default connect(mapStateToProps)(SidebarNavigation);
