import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'Language';
import { connect } from 'react-redux';
import { ReduxState, AppDispatch } from 'Store';
import Table from 'Common/components/BaseTable/Table';
import { getTableConfig } from './table-config';
import { Helmet } from 'react-helmet';
import { fetchKYCList } from 'KYC/redux/actions';
import { KYCList as KYCListType } from 'KYC/redux/types';
import KYCListTabs, { KYCListTabRoutes } from '../KYCListTabs/KYCListTabs';
import KYCListHeader from 'KYC/components/KYCListHeader';
import EmptyTableBody from 'Casefiles/Archive/EmptyTableBody';
import Analytics from 'Common/Analytics';
import LaunchDarkly, { Flags } from 'Common/LaunchDarkly';
import AccessDenied from 'Common/components/AccessDenied';

export type Props = {
    routeParams: {
        status: 'pending' | 'filled';
        query: any;
    };
    isFetching: boolean;
    items: KYCListType;
    dispatch: AppDispatch;
    error: any;
};

export type State = {
    hasError: boolean;
    paginationParams: {
        title: string;
        page: number;
        per_page: number;
        sort: string;
    };
};

export class KYCList extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            paginationParams: {
                title: '', // Search value
                page: 1,
                per_page: 10 /* eslint camelcase:0 */,
                ...this.getParamsFromRouteStatus(props.routeParams.status),
            },
            hasError: false,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // Refresh data on tab change
        if (prevProps.routeParams !== this.props.routeParams) {
            this.onTabChange();
        }

        // Refresh data on user table interaction
        if (prevState.paginationParams !== this.state.paginationParams) {
            this.loadData();
        }
    }

    // When the user changes tabs, we update the paginationParams to include the query parameters
    // for the selected tab (found in KYCListTabs.tsx).
    // Aditionally, the page in the table is reset to 1.
    onTabChange() {
        this.setState({
            paginationParams: {
                ...this.state.paginationParams,
                ...this.getParamsFromRouteStatus(this.props.routeParams.status),
                page: 1, // Go back to page 1 on tab change.
            },
        });
    }

    // Each tab of the KYC List (Pending / Filled) has a set of query parameters to paginate the
    // results from the kyc list endpoint. This function extracts the query parameters from the
    // tab routes (found in KYCListTabs.tsx)
    getParamsFromRouteStatus(status) {
        const [route] = KYCListTabRoutes.filter(
            (item) => item.route.params.status === status
        );

        return route.query;
    }

    /**
     * Fetches a paginated list of KYCs using the current pagination parameters
     */
    loadData() {
        const { dispatch } = this.props;

        dispatch(fetchKYCList(this.state.paginationParams));
    }

    handleTableChange(type, value) {
        const { paginationParams } = this.state;

        this.setState(
            {
                paginationParams: {
                    ...paginationParams,
                    [type]: value,
                },
            },
            () => {
                Analytics.track('kyc management - table settings changed', {
                    [type]: value,
                });
            }
        );
    }

    handleOnSearch = (search: string) => {
        this.setState(
            {
                paginationParams: {
                    ...this.state.paginationParams,
                    title: search,
                },
            },
            () => {
                // @todo: Throttle search events to avoid over-tracking search events
                // For now, this event will give an indication on whether users actually use the search function.
                Analytics.track('kyc management - search term changed');
            }
        );
    };

    clearSearch = () => {
        this.setState({
            paginationParams: {
                ...this.state.paginationParams,
                title: '',
            },
        });
    };

    render() {
        const { items, isFetching, routeParams } = this.props;
        const { paginationParams } = this.state;

        // Display an access denied page if the user doesn't have the required feature flag
        if (!LaunchDarkly.variation(Flags.KYC_LIST_ENABLED)) {
            return <AccessDenied pageName={i18n`Manage KYC`} />;
        }

        return (
            <div className="archive-container">
                <Helmet>
                    <title>{i18n`Identity validation (KYC)`}</title>
                </Helmet>

                <div className="white-container no-padding-container">
                    <h3 className="title">{i18n`Follow up on identity validations`}</h3>
                    <KYCListTabs>
                        <Table
                            limit={paginationParams.per_page}
                            page={paginationParams.page}
                            sort={paginationParams.sort}
                            config={getTableConfig(
                                this.props.routeParams.status,
                                this.loadData.bind(this)
                            )}
                            dataSource={items.data}
                            dataCount={items.count}
                            onSortChange={(sort) =>
                                this.handleTableChange('sort', sort)
                            }
                            onLimitChange={(per_page) =>
                                this.handleTableChange('per_page', per_page)
                            }
                            onNext={(page) =>
                                this.handleTableChange('page', page)
                            }
                            onPrev={(page) =>
                                this.handleTableChange('page', page)
                            }
                            onPageChange={(page) =>
                                this.handleTableChange('page', page)
                            }
                            isLoading={isFetching}
                            hasError={!!this.props.error}
                            emptyTableComponent={
                                <EmptyTableBody
                                    message={
                                        routeParams.status === 'filled'
                                            ? i18n`There are no filled identity validations yet`
                                            : i18n`There are no pending identity validations`
                                    }
                                    itemCount={items.count}
                                    clearSearch={this.clearSearch}
                                    search={this.state.paginationParams.title}
                                />
                            }
                            headComponent={
                                <KYCListHeader
                                    dataCount={items.count}
                                    searchValue={
                                        this.state.paginationParams.title
                                    }
                                    onSearchInputChange={this.handleOnSearch}
                                    clearSearch={this.clearSearch}
                                />
                            }
                        />
                    </KYCListTabs>
                </div>
            </div>
        );
    }
}

export default connect((state: ReduxState) => {
    return {
        items: state.kyc.items,
        isFetching: state.kyc.items.isFetching,
        error: state.kyc.items.error,
    };
})(KYCList);
