import PropTypes from 'prop-types';
import React from 'react';
import hotkeys from 'hotkeys-js';
import LoginUtils from 'Auth/utils/login';
import DashboardHeader from './DashboardHeader';
import DashboardContent from './DashboardMainPane';
import MobileDashboardHeader from './DashboardHeader-Mobile';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import SidebarNavigation from 'Common/components/SidebarNavigation';

// Product News
import NewsStore from 'News/stores/NewsStore';
import NewsFeed from 'News/components/NewsFeed';

import { StorageKeys } from 'Constants';
import uiState from 'Core/uiState';
import launchDarkly, { Flags } from 'Common/LaunchDarkly';
import { viewportSize } from 'utils';

export default class DashboardContainer extends React.Component {
    static propTypes = {
        menu: PropTypes.array,
        children: PropTypes.object,
        routes: PropTypes.array,
        main: PropTypes.object,
        pane: PropTypes.object,
        user: PropTypes.object,
        customer: PropTypes.object,
        settings: PropTypes.object,
        branding: PropTypes.object,
    };

    static defaultProps = {
        user: {},
    };

    state = {
        activeMenu: {},
        size: null,
        menuOpen: false,
        collapsed: uiState.get(StorageKeys.SIDEBAR_COLLAPSED, false),
        showNews: NewsStore.getState().open,
    };

    componentWillMount() {
        NewsStore.addChangeListener(this.onChange);
        hotkeys('ctrl+/, command+/', this.toggleCollapse);
        window.addEventListener('resize', this.setViewportSize);
    }

    componentDidMount() {
        const signerArchiveEnabled = launchDarkly.variation(
            Flags.ENABLE_SIGNERS_ARCHIVE
        );

        // A user may end up coming here with a personal account and so the
        // 'customer' prop will be undefined. When that happens, this acts as a
        // failsafe and redirects the user back the default route
        if (!this.props.customer && !signerArchiveEnabled) {
            const defaultRoute = LoginUtils.getDefaultRoute();

            if (defaultRoute.type === 'route') {
                return this.context.router.replace(defaultRoute.route);
            }

            return window.location.replace(defaultRoute.url);
        }

        this.setViewportSize();
    }

    componentWillUnmount() {
        NewsStore.removeChangeListener(this.onChange);
        hotkeys.unbind('ctrl+/, command+/', this.toggleCollapse);
        window.removeEventListener('resize', this.setViewportSize);
    }

    toggleCollapse = () => {
        const collapsed = !this.state.collapsed;

        this.setState(
            { collapsed: collapsed },
            uiState.set(StorageKeys.SIDEBAR_COLLAPSED, collapsed)
        );
    };

    onChange = () => {
        let newsState = NewsStore.getState();

        this.setState({
            showNews: newsState.open,
        });
    };

    setViewportSize = () => {
        let size = viewportSize();

        this.setState({ size: size });
    };

    toggleMenu = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    };

    render() {
        const { customer, settings } = this.props;
        let { size, menuOpen, showNews } = this.state;
        let mobile = size === 'small';

        // Only allow collapsing in New UI in desktop view
        const collapsed = this.state.collapsed && !mobile;

        const signerArchiveEnabled = launchDarkly.variation(
            Flags.ENABLE_SIGNERS_ARCHIVE
        );

        if (!customer && !signerArchiveEnabled) return null;

        const displaySigner = !customer;

        return (
            <div className="penneo-dashboard">
                {mobile ? (
                    <MobileDashboardHeader
                        user={this.props.user}
                        branding={this.props.branding}
                        open={this.state.menuOpen}
                        toggleMenu={this.toggleMenu}
                        displaySigner={displaySigner}
                    />
                ) : (
                    <DashboardHeader
                        displaySigner={displaySigner}
                        user={this.props.user}
                        branding={this.props.branding}
                        toggleCollapse={() => this.toggleCollapse()}
                        isCollapsed={collapsed}
                    />
                )}

                <SidebarNavigation
                    branding={this.props.branding}
                    routes={this.props.routes}
                    user={this.props.user}
                    collapsed={collapsed}
                    mobile={mobile}
                    customer={displaySigner ? undefined : customer}
                    settings={settings}
                    mobileMenuOpen={menuOpen}
                    onMenuItemClick={() => this.toggleMenu(false)}
                    toggleCollapse={() => this.toggleCollapse()}
                />

                {/* This transition block makes the children components stay rendered for 300ms
                    longer, which allows a nested TransitionGroup to fully execute the animation
                    i.e. If this transition is removed, the product news will close without
                    a slide and opacity animation
                */}
                {customer && (
                    <TransitionGroup
                        className="news-feed-leave-transition"
                        enter={false}
                        exit={true}>
                        {showNews && (
                            <CSSTransition timeout={{ exit: 300 }}>
                                <NewsFeed />
                            </CSSTransition>
                        )}
                    </TransitionGroup>
                )}

                <DashboardContent collapsed={collapsed} {...this.props} />
            </div>
        );
    }
}
